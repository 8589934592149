import React from "react"
import {Mobility} from "../mobility/Mobility";
import {StudentDetails} from "../student/StudentDetails";
import {DynamicDocument} from "../common/DynamicDocument";
import {CoursesTable} from "../course/CoursesTable";

export const LearningAgreement = ({learningAgreement}) => {
    return (
        learningAgreement !== null
        && learningAgreement !== undefined
        && learningAgreement.hasOwnProperty('mobility')
        && learningAgreement.hasOwnProperty('student')
        &&
        <DynamicDocument sections={[
            {
                anchor: 'mobility',
                header: 'Mobility details',
                body: <Mobility mobility={learningAgreement.mobility} />
            },
            {
                anchor: 'student',
                header: 'Student details',
                body: <StudentDetails student={learningAgreement.student} />
            },
            {
                anchor: 'sending-courses',
                header: 'Courses at sending university',
                body: <CoursesTable courses={learningAgreement.sendingCourses} />
            },
            {
                anchor: 'receiving-courses',
                header: 'Courses at receiving university',
                body: <CoursesTable courses={learningAgreement.receivingCourses} />
            },
        ]} />
    )
}