import React from "react"
import {Button} from "react-bootstrap";
import {Download} from "react-bootstrap-icons";

export const PdfButton = ({learningAgreement}) => {
    if (learningAgreement !== undefined) {
        return (
            learningAgreement.pdf === undefined ?
                <Button variant={'outline-primary'} disabled title={"The PDF download will be available only when the receiving university has accepted your learning agreement"}><Download /> Download <strong>official version</strong> (last version accepted by all 3 parties) as PDF</Button>
                :
                <a download target="_blank" className={'btn btn-outline-primary'} href={process.env.REACT_APP_API_URL + learningAgreement.pdf} rel="noreferrer"><Download /> Download <strong>official version</strong> (last version accepted by all 3 parties) as PDF</a>
        )
    }
}