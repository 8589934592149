import React from "react"
import {Button} from "react-bootstrap";
import {Download} from "react-bootstrap-icons";

export const PdfButton = ({mobility}) => {
    if (mobility !== undefined) {
        return (
            mobility.creditsRecognitionPdf === undefined ?
                <Button variant={'outline-primary'} disabled title={"The PDF download will be available only when the receiving university has submitted the transcript of records"}><Download /> Download as PDF</Button>
                :
                <a download target="_blank" className={'btn btn-outline-primary'} href={process.env.REACT_APP_API_URL + mobility.creditsRecognitionPdf} rel="noreferrer"><Download /> Download as PDF</a>
        )
    }
}