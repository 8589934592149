
export var gender = {
	0: "Not known",
	1: "Male",
	2: "Female",
	9: "Not applicable",
}

export var eqfLevels = {
	5: "5 - Diploma of higher education",
	6: "6 - Bachelor degree",
	7: "7 - Master degree",
	8: "8 - Doctorate degree, PhD",
}

export var languageLevels = {
	"A1": "A1 (Beginners)",
	"A2": "A2 (Pre-intermediate)",
	"B1": "B1 (Intermediate)",
	"B2": "B2 (Upper-intermediate)",
	"C1": "C1 (Advanced)",
	"C2": "C2 (Proficiency)",
	"NS": "Native speaker",
}

export var status = {
	"draft": "Draft",
	"submitted_by_student": "Submitted by student",
	"accepted_by_sending": "Accepted by sending",
	"rejected_by_sending": "Rejected by sending",
	"accepted_by_receiving": "Accepted by receiving",
	"rejected_by_receiving": "Rejected by receiving",
}

export var statusColorOutgoing = {
	'draft': 'grey',
	'submitted_by_student': 'orange',
	'accepted_by_sending': 'blue',
	'rejected_by_sending': 'lightblue',
	'accepted_by_receiving': 'green',
	'rejected_by_receiving': 'red',
}

export var learningAgreementStatusColorStudent = {
	'draft': 'secondary',
	'submitted_by_student': 'primary',
	'accepted_by_sending': 'primary',
	'rejected_by_sending': 'danger',
	'accepted_by_receiving': 'success',
	'rejected_by_receiving': 'danger',
}
