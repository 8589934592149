import React from 'react'
import {MobilityForm} from "../form/MobilityForm";
import {Page} from "./Page";

export const MobilityCreatePage = () => {

    return (
        <Page
            breadcrumbs={[
                {label: 'Create new mobility', active: true},
            ]}
            title={'Create new mobility'}
        >
            <MobilityForm />
        </Page>
    )
}