import React from 'react'
import {Header} from "./common/Header";
import {Col, Container, Row} from "react-bootstrap";
import {Sidebar} from "./common/Sidebar";
import {Route, Routes} from "react-router-dom";
import {Protected} from "../Protected";
import {Footer} from "./common/Footer";
import generalRoutes from "../routes/general";
import policiesRoutes from "../routes/policies";
import learningagreementRoutes from "../routes/learningAgreement";
import mobilityRoutes from "../routes/mobility";
import studentRoutes from "../routes/student";
import heiRoutes from "../routes/hei";
import staffRoutes from "../routes/staff";
import transcriptOfRecordsRoutes from "../routes/transcriptOfRecords";
import {useAuth} from "../utils/hooks/useAuth";
import {PoliciesAcceptation} from "./gdpr/PoliciesAcceptation";

export const Layout = () => {
    const {isAuthenticated} = useAuth()
    const routeCollections = [
        generalRoutes,
        policiesRoutes,
        learningagreementRoutes,
        mobilityRoutes,
        studentRoutes,
        heiRoutes,
        transcriptOfRecordsRoutes,
        staffRoutes,
    ]

    return (
        <>
            <Header />
            <Container fluid>
                <Row>
                    <Sidebar />
                    <Col md={isAuthenticated() ? 9: 12} xl={isAuthenticated() ? 9: 12}>
                        <Routes>
                            {
                                // TODO lazy loading for Routes
                                routeCollections.map(
                                    (routeCollection, indexCollection) =>
                                        routeCollection.map(
                                            (route, indexRoute) =>
                                                <Route key={indexCollection+'.'+indexRoute} path={route.path}
                                                       element={
                                                           (route.protected === undefined || route.protected === true) ?
                                                               <Protected>{route.element}</Protected>
                                                               : route.element
                                                       }
                                                />
                                        )
                                )
                            }
                        </Routes>

                        <Footer />
                    </Col>
                </Row>
            </Container>
            <PoliciesAcceptation/>
        </>
    )
}