import React from 'react'
import {Page} from "./Page";

export const TermsAndConditionsPage = () => {

    return (
        <Page
            breadcrumbs={[
                {label: 'Terms and conditions', active: true},
            ]}
            title={'Terms and conditions'}
        >
            <p>
                To be completed...
            </p>
        </Page>
    )
}