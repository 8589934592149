import React from 'react'
import {RejectionAlert} from "./RejectionAlert";

export const LearningAgreementStudent = (props) => {
    const learningAgreement = props.learningAgreement

    return (
        <>
            <RejectionAlert
                learningAgreement={learningAgreement}
                mode={'student'}
            />
        </>
    )
}
