import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import { CookiesProvider } from 'react-cookie'
import { UserProvider } from './utils/context/UserProvider'
import {HeisProvider} from "./utils/context/HeisProvider";
import {Layout} from "./components/Layout";
import {FlashBagProvider} from "./utils/context/FlashBagProvider";
import {AuthProvider} from "./utils/context/AuthProvider";
import {ApiProvider} from "./utils/context/ApiProvider";

function App() {
    return (
        <BrowserRouter>
            <CookiesProvider>
                <FlashBagProvider>
                    <AuthProvider>
                        <ApiProvider>
                            <HeisProvider>
                                <UserProvider>
                                    <div className="App">
                                        <Layout/>
                                    </div>
                                </UserProvider>
                            </HeisProvider>
                        </ApiProvider>
                    </AuthProvider>
                </FlashBagProvider>
            </CookiesProvider>
        </BrowserRouter>
    )
}

export default App
