import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { prettifyDate } from '../../utils/Utils'
import {HeiLink} from "../hei/HeiLink";
import {ContactDetails} from "../contactDetails/ContactDetails";
import {eqfLevels} from "../../utils/GlobalVars";
const iscedFCodes = require('../../utils/iscedFCodes.json')//TODO

export const Mobility = (props) => {
  let mobility = props.mobility

  if(Object.keys(mobility).length !== 0) {
    return (
      <>
        <Container className="mt-5 pb-5 border-bottom">

          <Row>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Start date</p>
                </Col>
                <Col>
                  <p className="ms-2">{prettifyDate(mobility.startDate)}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">End date</p>
                </Col>
                <Col>
                  <p className="ms-2">{prettifyDate(mobility.endDate)}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-0">
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">ISCED</p>
                </Col>
                <Col>
                  <p className="ms-2">{mobility.iscedFCode}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">EQF level at departure</p>
                </Col>
                <Col>
                  <p className="ms-2">{eqfLevels[mobility.eqfLevelAtDeparture]}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mb-0">
            <Col md={2}>
              <p className="fw-bold">ISCED Clarification</p>
            </Col>
            <Col md={10}>
              <p className="ms-2">{
                mobility.iscedClarification !== undefined ?
                    mobility.iscedClarification
                    :'Not communicated'
              }</p>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Sending university</p>
                </Col>
                <Col>
                  <p className="ms-2">
                    <HeiLink
                        hei={mobility.sendingHei}
                    />
                  </p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Faculty at sending university</p>
                </Col>
                <Col>
                  <p className="ms-2">
                  {
                    mobility.sendingOUnit !== undefined ?
                      mobility.sendingOUnit.name
                        : 'Not communicated'
                  }
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Receiving university</p>
                </Col>
                <Col>
                  <p className="ms-2">
                    <HeiLink
                        hei={mobility.receivingHei}
                    />
                  </p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Faculty at receiving university</p>
                </Col>
                <Col>
                  <p className="ms-2">
                    {
                      mobility.receivingOUnit !== undefined ?
                          mobility.receivingOUnit.name
                          : 'Not communicated'
                    }
                  </p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Academic year at receiving university</p>
                </Col>
                <Col>
                  <p className="ms-2">{mobility.receivingHeiAcademicYear}</p>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>

        {
          [
            {
              role: 'sending',
              contactPerson: mobility.sendingHeiContactPerson
            },
            {
              role: 'receiving',
              contactPerson: mobility.receivingHeiContactPerson
            },
          ].map(
              (heiDetails, index) =>
                  <Container className="mt-5 pb-5 border-bottom" key={index}>
                    <Row className="mb-3">
                      <Col>
                        <h5 className="text-primary">Contact at {heiDetails.role} university</h5>
                      </Col>
                    </Row>

                    <ContactDetails
                        contactDetails={heiDetails.contactPerson}
                    />
                  </Container>
          )
        }
      </>
    )
  }
}

Mobility.propTypes = {
  mobility: PropTypes.object
}