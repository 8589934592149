import React, {useEffect} from 'react'
import { useForm, Controller } from "react-hook-form";
import {Button, Col, FloatingLabel, Form, Row} from "react-bootstrap";
import {useLocation} from "react-router-dom";
import Select from "react-select";
import {SelectHeiFilter} from "./SelectHeiFilter";

export const MobilityFilterForm = ({filterCallback}) => {
    const { reset, handleSubmit, control, setValue, getValues, formState: { isSubmitting, errors } } = useForm({defaultValues: {}});

    const { search } = useLocation();
    let query = React.useMemo(() => new URLSearchParams(search), [search])

    let filters=[
        'student.contactDetails.givenNames',
        'startDate[before]',
        'startDate[after]',
        'learningAgreement.status',
        'receivingHei.schacCode'
    ]

    useEffect(() => {
        // Pre-fill filters with query parameters
        filters.forEach(
            (filterId) => {
                let queryParameter = query.get(filterId)
                if (queryParameter !== null) {
                    setValue(filterId, queryParameter)
                }
            }
        )
    }, [])


    const onSubmit = () => {
        let filterData = []

        filters.forEach(
            (filterId) => {
                let value = getValues(filterId)
                if (value !== undefined) {
                    if (Array.isArray(value)) {
                        let arrayValue = []
                        value.forEach(
                            (valuePair) => {
                                arrayValue.push(valuePair.value)
                            }
                        )
                        value = arrayValue
                    } else if (value.hasOwnProperty('value')) {
                        value = value.value
                    }
                    filterData[filterId] = value
                }
            }
        )

        filterCallback(filterData)
    }



    return (
        <Form onSubmit={handleSubmit(onSubmit)} onReset={reset} >
            <Row>
                <Col md={2}>

                    <FloatingLabel
                        className="mb-3"
                        controlId={'student.contactDetails.givenNames'}
                        label={'Student'}
                    >
                        <Controller control={control}
                                    name={'student.contactDetails.givenNames'}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Form.Control
                                            {...field}
                                            type='text'
                                            placeholder={'Student'}
                                            isInvalid={errors['student.contactDetails.givenNames']}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors['student.contactDetails.givenNames']?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>
                <Col md={2}>

                    <FloatingLabel
                        className="mb-3"
                        controlId={'startDate[before]'}
                        label={'Starts before'}
                    >
                        <Controller control={control}
                                    name={'startDate[before]'}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Form.Control
                                            {...field}
                                            type='date'
                                            placeholder={'Starts before'}
                                            isInvalid={errors['startDate[before]']}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors['startDate[before]']?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>



                <Col md={2}>

                    <FloatingLabel
                        className="mb-3"
                        controlId={'startDate[after]'}
                        label={'Starts after'}
                    >
                        <Controller control={control}
                                    name={'startDate[after]'}
                                    defaultValue={''}
                                    render={({ field }) => (
                                        <Form.Control
                                            {...field}
                                            type='date'
                                            placeholder={'Starts after'}
                                            isInvalid={errors['startDate[after]']}
                                        />
                                    )}
                        />
                        <Form.Control.Feedback type="invalid">
                            {errors['startDate[after]']?.message}
                        </Form.Control.Feedback>
                    </FloatingLabel>
                </Col>

                <Col md={6}>
                    <Controller
                        name="learningAgreement.status"
                        control={control}
                        render={({ field }) => <Select
                            {...field}
                            isMulti
                            placeholder={'Filter LA statuses'}
                            defaultValue={[]}
                            options={[
                                { value: "submitted_by_student", label: "Submitted by student" },
                                { value: "accepted_by_sending", label: "Accepted by sending" },
                                { value: "rejected_by_sending", label: "Rejected by sending" },
                                { value: "accepted_by_receiving", label: "Accepted by receiving" },
                                { value: "rejected_by_receiving", label: "Rejected by receiving" },
                            ]}
                        />}
                    />
                </Col>

                <Col md={6}>
                    <SelectHeiFilter inputName={'receivingHei.schacCode'} control={control} />
                </Col>


                <Col md={4}>
                    <Button variant={'primary'} type="submit">
                        Filter
                    </Button> <Button
                    variant={'outline-warning'}
                    onClick={() => {
                        reset()
                        setValue('learningAgreement.status', [])
                        setValue('receivingHei.schacCode', '')
                        onSubmit()
                    }}
                    disabled={isSubmitting}
                >
                    Reset filters
                </Button>
                </Col>
            </Row>
        </Form>
    );
}