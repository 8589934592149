import React from 'react'
import { Row, Col } from 'react-bootstrap'
import {FlashMessage} from "../common/FlashMessage";

export const LoginPage = () => {
    return (
        <>
            <Row className="mx-auto pt-5">
                <Col md={4} className="offset-md-4">
                    <h2>Login</h2>
                    <FlashMessage/>

                    <a className="btn btn-primary" href={process.env.REACT_APP_API_URL + "/oauth/asean_idp"}>Log in with the ASEAN Identity provider</a>
                </Col>
            </Row>
        </>
    )
}
