// List of transcript of records statuses
export const tor_status_AVAILABLE = 'Available'
export const tor_status_NOT_AVAILABLE = 'Not available'

export function transcriptOfRecordsStatus(transcriptOfRecords) {
    if (
        transcriptOfRecords === null
        || transcriptOfRecords === undefined
    ) {
        return tor_status_NOT_AVAILABLE
    }

    return tor_status_AVAILABLE
}

export function transcriptOfRecordsStatusColorOutgoing(transcriptOfRecords) {
    switch (transcriptOfRecordsStatus(transcriptOfRecords)) {
        case tor_status_AVAILABLE:
            return 'success'
        case tor_status_NOT_AVAILABLE:
            return 'secondary'
        default:
            return 'secondary'
    }
}