import React, {createContext, useState} from 'react'

export const CreditsCoefficientContext = createContext([])

export function CreditsCoefficientProvider({ children }) {
    const [creditsCoefficient, setCreditsCoefficient] = useState(null)

    return (
        <CreditsCoefficientContext.Provider value={{creditsCoefficient, setCreditsCoefficient}}>
            {children}
        </CreditsCoefficientContext.Provider>
    )
}