import React from 'react'
import {createEQFLevelLabelValuePair} from "../../../utils/Utils";
import {Select} from "../base/Select";

export const SelectEqfLevel = (props) => {
    let label = props.label || 'EQF level'
    let placeholder = props.placeholder || 'Select EQF level'

    const options = createEQFLevelLabelValuePair()

    return <Select
        {...props}
        label={label}
        placeholder={placeholder}
        options={options}
    />
}