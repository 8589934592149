import React from "react";
import {Form} from "./base/Form";
import {HeiSettings} from "./type/HeiSettings";

export const HeiSettingsForm = ({hei}) => {
    return (
        <Form
            data={hei}
            fields={HeiSettings}
            method="patch"
            submitUrl={"/api/heis/"+hei.schacCode+"/settings"}
            successMessage="The university settings were saved."
            redirectOnSuccess={
                heiResponse => {
                    return '/university/' + heiResponse.schacCode
                }
            }
        />
    )
}