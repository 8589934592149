import {useContext, useState} from "react";
import {ApiContext} from "../context/ApiProvider";

export const useApi = (
        {
            url,
            method,
            body,
            queryParameters= {},
            isCollection = false,
            thenCallback,
            errorCallback,
            finallyCallback
        }
    ) => {

    const [data, setData] = useState(isCollection? [] : null)
    const [errorNotFound, setErrorNotFound] = useState(false)
    const [isLoaded, setLoaded] = useState(false)

    const { apiFetch } = useContext(ApiContext)

    const resetData = () => {
        if (isCollection) {
            setData([])
        } else {
            setData(null)
        }
    }

    const queryApi = () => {
        setErrorNotFound(false)
        setLoaded(false)

        apiFetch(
            {
                url: url,
                method: method,
                body: body,
                queryParameters: queryParameters,
                thenCallback: (response) => {
                    if (isCollection) {
                        setData(response['hydra:member'])
                    } else {
                        setData(response)
                    }

                    if (thenCallback !== undefined) {
                        thenCallback(response)
                    }
                },
                errorCallback: (error) => {
                    setErrorNotFound(true)
                    if (errorCallback !== undefined) {
                        errorCallback(error)
                    }
                },
                finallyCallback: () => {
                    setLoaded(true)
                    if (finallyCallback !== undefined) {
                        finallyCallback()
                    }
                }
            }
        )
    }

    return {data, errorNotFound, isLoaded, queryApi, resetData};
};