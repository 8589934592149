import {Badge} from "react-bootstrap";
import {useEffect, useState} from "react";

export const TimelineIndicator = ({mobility}) => {
    const FUTURE = 'Future'
    const ONGOING = 'Ongoing'
    const COMPLETED = 'Completed'

    const [background, setBackground] = useState('secondary')
    const [indicator, setIndicator] = useState('')

    const updateAspect = () => {
        let today = new Date()
        let mobilityStart = new Date(mobility.startDate)
        let mobilityEnd = new Date(mobility.endDate)

        let indicator
        if (
            today < mobilityStart
        ) {
            indicator = FUTURE
            setBackground()
        } else if (today <= mobilityEnd) {
            indicator = ONGOING
            setBackground('')
        } else {
            indicator = COMPLETED
        }
        setIndicator(indicator)
        setBackground(indicatorToBackground(indicator))
    }

    const indicatorToBackground = (indicator) => {
        switch (indicator) {
            case FUTURE:
                return 'warning'
            case ONGOING:
                return 'success'
            case COMPLETED:
            default:
                return 'secondary'
        }
    }

    useEffect(() => {
        updateAspect()
    }, [])

    return <Badge bg={background}>
            {indicator}
        </Badge>
}