import React from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col } from 'react-bootstrap'
import { prettifyDate, parseGenderData, getCitizenship } from '../../utils/Utils'
import { ContactDetails } from "../contactDetails/ContactDetails";
import {languageLevels} from "../../utils/GlobalVars";
const languages = require('../../utils/languages.json')

export const StudentDetails = ({ student }) => {
  const getLanguageNameWithNative = (code) => {
    let language = languages.find(language => (code === language.code))

    return language.name + ' (' + language.native + ')'
  }

  const sortLanguageLevelsByLevelDesc = (languageLevels) => {
    return languageLevels.sort(
        (languageLevelFirst, languageLevelSecond) => {
          if (languageLevelFirst.level > languageLevelSecond.level){
            return -1
          }
          if (languageLevelFirst.level < languageLevelSecond.level){
            return 1
          }
          return 0;
        }
    )
  }

  if(Object.keys(student).length !== 0) {
    return (

      <>
        <Container className="mt-5 pb-5 border-bottom">
          <ContactDetails
            contactDetails={student.contactDetails}
          />

          <Row>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Birth date</p>
                </Col>
                <Col>
                  <p className="ms-2">{prettifyDate(student.birthDate)}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Gender</p>
                </Col>
                <Col>
                  <p className="ms-2">{parseGenderData(student.gender)}</p>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row>
            <Col>
              <Row className="mb-0">
                <Col md={4}>
                  <p className="fw-bold">Citizenship</p>
                </Col>
                <Col>
                  <p className="ms-2">{getCitizenship(student.citizenship)}</p>
                </Col>
              </Row>
            </Col>
            <Col>
              <p className="fw-bold">Language levels</p>
              <ul className="ms-2">
                {
                  sortLanguageLevelsByLevelDesc(student.languageLevels).map(
                      (languageLevel, index) =>
                          <li key={index}>
                            <Row>
                              <Col md={6}>{getLanguageNameWithNative(languageLevel.language)}</Col>
                              <Col md={6}><strong>{languageLevels[languageLevel.level]}</strong></Col>
                            </Row>
                          </li>
                  )
                }
              </ul>
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}

StudentDetails.propTypes = {
  student: PropTypes.object
}