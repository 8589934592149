import React, {useEffect, useState} from 'react'
import {Alert, Button, Col, Row} from "react-bootstrap";
import {FormField} from "../base/Form";
import {ContactDetails} from "./ContactDetails";
import {useFormContext} from "react-hook-form";
import {useApi} from "../../../utils/hooks/useApi";

export const HeiContactDetailsLoadingDefaultButton = ({controlId, heiControlId, heiRole}) => {
    const { resetField, getValues, setFocus } = useFormContext();
    const [defaultContactWarningShown, setDefaultContactWarningShown] = useState(false)
    const [heiIri, setHeiIri] = useState(null)

    const subIds = [
        'givenNames',
        'familyNames',
        'email',
        'phoneNumber',
    ]

    // Hei
    const {
        queryApi: heiQueryApi
    } = useApi(
        {
            url: heiIri,
            method: 'get',
            thenCallback: (hei) => {
                if (hei.hasOwnProperty('defaultMobilityContactDetails')) {
                    // Fill input fields with
                    subIds.forEach(
                        subId => {
                            if (hei.defaultMobilityContactDetails.hasOwnProperty(subId)) {
                                // Change input's value and manually trigger React's onchange event
                                let element = document.getElementById(controlId + '.' + subId)

                                let nativeInputValueSetter = Object.getOwnPropertyDescriptor(window.HTMLInputElement.prototype, "value").set;
                                nativeInputValueSetter.call(element, hei.defaultMobilityContactDetails[subId]);

                                let event = new Event('input', { bubbles: true});
                                element.dispatchEvent(event);
                            }
                        })
                } else {
                    setDefaultContactWarningShown(<><strong>{hei.name}</strong> did not fill in a default contact person</>)
                    setFocus(controlId+'.'+subIds[0])
                }
            }
        }
    )

    // TODO replace by useWatch
    useEffect(
        () => {
            if (heiIri !== null) {
                heiQueryApi()
            }
        }, [heiIri]
    )

    const fillContactDetailsWithDefault = () => {
        setDefaultContactWarningShown(false)

        // Reset affected fields for UX clarity
        subIds.forEach(
            subId => resetField(controlId+'.'+subId)
        )

        let heiFieldValue = getValues(heiControlId)
        if (heiFieldValue === undefined) {
            setHeiIri(null)
            alert('Select university first')
            setFocus(heiControlId)
        } else {
            setHeiIri(heiFieldValue)
        }
    }


    return (
        <>
            <h4 className="mb-4">Contact person at the {heiRole} university</h4>
            <Row>
                <Col md={5}>
                    <Button className={'mb-3'} variant={"outline-primary"}
                            onClick={fillContactDetailsWithDefault}>
                        Load {heiRole} university's default contact person
                    </Button>
                </Col>
                <Col md={7}>
                    <Alert variant="warning"
                           show={defaultContactWarningShown !== false}>
                        {defaultContactWarningShown}
                    </Alert>
                </Col>
            </Row>
            <FormField
                field={{
                    colWrapperMd: 12,
                    controlId: controlId,
                    type: ContactDetails,
                }}
            />
        </>
    )
}