import React, {useEffect, useState} from 'react'
import {Link, useParams} from "react-router-dom";
import {Alert} from "react-bootstrap";
import {TranscriptOfRecordsForm} from "../form/TranscriptOfRecordsForm";
import {InfoCircle} from "react-bootstrap-icons";
import {Page} from "./Page";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

const initialState = {
    mobility: null,
    mobilityLoaded: false,
    alreadyExists: false,
    isCreatable: false,
    learningAgreement: null,
    defaultValues: {},
    errorNotFound: false,
    errorLearningAgreementNotFound: false,
    learningAgreementLoaded: false
}

export const TranscriptOfRecordsCreatePage = () => {
    let { mobilityId } = useParams()

    const [defaultValues, setDefaultValues] = useState(initialState.defaultValues)
    const [alreadyExists, setAlreadyExists] = useState(initialState.alreadyExists)
    const [isCreatable, setCreatable] = useState(initialState.isCreatable)

    const {
        errorNotFound: errorMobilityNotFound,
        isLoaded: isMobilityLoaded,
        data: mobility,
        queryApi: mobilityQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId,
            method: 'get',
        }
    )

    const {
        errorNotFound: errorLearningAgreementNotFound,
        isLoaded: isLearningAgreementLoaded,
        data: learningAgreement,
        queryApi: learningAgreementQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/learning_agreement',
            method: 'get',
        }
    )

    useEffect(
        () => {
            mobilityQueryApi()
        }, [mobilityId]
    )

    useEffect(() => {
        setAlreadyExists(initialState.alreadyExists)
        setCreatable(initialState.isCreatable)

        if (
            mobility !== null
            && mobility.learningAgreement !== undefined
        ) {
            if (mobility.transcriptOfRecords === undefined) {
                setCreatable(isTranscriptOfRecordsCreatable(mobility))
                learningAgreementQueryApi()
            } else {
                setAlreadyExists(true)
            }
        }
    }, [mobility])

    useEffect(() => {
        loadDefaultValuesFromLearningAgreement()
    }, [learningAgreement])

    const loadDefaultValuesFromLearningAgreement = () => {
        if (learningAgreement === null) {
            // Reset default value
            setDefaultValues(initialState.defaultValues)
        } else {
            let defaultValuesFromLearningAgreement = {
                courseGrades: [],
                mobility: mobility['@id'],
                student: mobility.student['@id']
            }

            learningAgreement.receivingCourses.forEach((course) => {
                defaultValuesFromLearningAgreement.courseGrades.push(
                    {
                        course: {
                            'losCode': course.losCode,
                            'title': course.title,
                            'credits': course.credits,
                            'recognitionConditions': course.recognitionConditions,
                            'shortDescription': course.shortDescription,
                            'creditScheme': course.creditScheme,
                            'originatingFromLearningAgreement': course.originatingFromLearningAgreement,
                            'recognitionCredits': course.recognitionCredits,
                        }
                    }
                )
            })

            setDefaultValues(defaultValuesFromLearningAgreement)
        }
    }

    const isTranscriptOfRecordsCreatable = (mobility) => {
        // TODO insert conditions on when a ToR can be created compared to the end of a mobility
        return true;
    }

    return (
        <Page
            breadcrumbs={[
                {label: 'Mobility', url: '/mobility/' + mobilityId},
                {label: 'Create transcript of records', active: true},
            ]}
            title={'Create transcript of records'}
        >
            {
                errorMobilityNotFound?
                    <Alert variant={'danger'}>
                        No mobility found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isMobilityLoaded ?
                        <LoadingSpinner/>
                        :
                        alreadyExists ?
                            <Alert variant={'warning'}>
                                The transcript of records for this mobility <Link to={'/transcript_of_records/' + mobilityId}>already exists</Link>.
                            </Alert>
                            :
                            isCreatable ?
                                errorLearningAgreementNotFound?
                                    <Alert variant={'danger'}>
                                        No learning agreement found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                                    </Alert>
                                    :
                                    !isLearningAgreementLoaded ?
                                        <LoadingSpinner/>
                                        :
                                        <>
                                            <Alert variant={'info'}>
                                                <InfoCircle/> The transcript of records has been pre-filled by the courses selected by the student in <Link to={'/learning_agreement/' + mobilityId}>their learning agreement</Link>.
                                                <br/> You can add here the additional courses that a student took besides the ones in their learning agreement and the result they received for those.
                                            </Alert>

                                            <TranscriptOfRecordsForm
                                                transcriptOfRecords={defaultValues}
                                            />
                                        </>
                                :
                                <Alert variant={'warning'}>
                                    The mobility isn't complete, the transcript of records cannot be submitted until then.
                                </Alert>
            }
        </Page>
    )
}