import React from "react"
import {Mobility} from "../mobility/Mobility";
import {StudentDetails} from "../student/StudentDetails";
import {DynamicDocument} from "../common/DynamicDocument";
import {CourseGradesTable} from "./CourseGradesTable";

export const TranscriptOfRecords = ({transcriptOfRecords}) => {
    return (
        transcriptOfRecords !== null
        && transcriptOfRecords !== undefined
        && transcriptOfRecords.hasOwnProperty('mobility')
        && transcriptOfRecords.hasOwnProperty('student')
        &&
        <DynamicDocument sections={[
            {
                anchor: 'mobility',
                header: 'Mobility details',
                body: <Mobility mobility={transcriptOfRecords.mobility}/>
            },
            {
                anchor: 'student',
                header: 'Student details',
                body: <StudentDetails student={transcriptOfRecords.student}/>
            },
            {
                anchor: 'grades',
                header: 'Courses and grades at receiving university',
                body: <CourseGradesTable courseGrades={transcriptOfRecords.courseGrades}/>
            },
        ]}/>
    )
}