import React, {useEffect} from 'react'
import {Link, useParams} from "react-router-dom";
import {MobilityForm} from "../form/MobilityForm";
import {Alert} from "react-bootstrap";
import {Page} from "./Page";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

export const MobilityEditPage = () => {
    let { mobilityId } = useParams()

    const {
        errorNotFound: errorMobilityNotFound,
        isLoaded: isMobilityLoaded,
        data: mobility,
        queryApi: mobilityQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId,
            method: 'get',
        }
    )

    useEffect(
        () => {
            mobilityQueryApi()
        }, [mobilityId]
    )

    return (
        <Page
            breadcrumbs={[
                {label: 'Mobility', url: '/mobility/'+mobility?.id},
                {label: 'Edit mobility', active: true},
            ]}
            title={<>Edit mobility details <small className={'text-muted'}>[{mobility?.id}]</small></>}
        >
            {
                errorMobilityNotFound ?
                    <Alert variant={'danger'}>
                        No mobility found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isMobilityLoaded?
                        <LoadingSpinner/>
                        :
                        Object.keys(mobility).length > 0 &&
                            <MobilityForm
                                mobility={mobility}
                            />
            }
        </Page>
    )
}