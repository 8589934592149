import React, {useContext, useEffect, useState} from 'react'
import {Mobility} from "./type/Mobility";
import {Form} from "./base/Form";
import {UserContext} from "../../utils/context/UserProvider";

export const MobilityForm = ({mobility = {}}) => {
    const {currentUserStudent, refreshCurrentUserStudentMobilities} = useContext(UserContext)
    const [formattedMobility, setFormattedMobility] = useState({})
    const [method, setMethod] = useState(null)
    const [successMessage, setSuccessMessage] = useState(null)
    const [submitUrl, setSubmitUrl] = useState(null)

    const formatData = () => {
        if (Object.keys(mobility).includes('id')) {
            let formattedMobilityObject = JSON.parse(JSON.stringify(mobility))

            let relationKeys = ['receivingHei', 'sendingHei', 'student', 'sendingOUnit', 'receivingOUnit']
            // Change objects to IRIs
            relationKeys.forEach((relationKey) => {
                if (
                    Object.keys(mobility).includes(relationKey)
                    && Object.keys(mobility[relationKey]).includes('@id')
                ) {
                    formattedMobilityObject[relationKey] = mobility[relationKey]['@id']
                }
            })

            setFormattedMobility(formattedMobilityObject)
        }
    }

    const adaptToAction = () => {
        let actionIsEditing = mobility.id !== undefined
        if (actionIsEditing) {
            setMethod('patch')
            setSubmitUrl(mobility["@id"])
            setSuccessMessage('The changes in the mobility were saved.')
            formatData()
        } else {
            setMethod('post')
            setSubmitUrl('/api/mobilities')
            setSuccessMessage('Your new mobility was saved.')
        }
    }

    useEffect(() => {
        adaptToAction()
    }, [mobility])

    useEffect(() => {
        if (currentUserStudent !== null) {
            formattedMobility.student = currentUserStudent['@id']
            setFormattedMobility(formattedMobility)
        }
    }, [currentUserStudent])

    if (
        Object.keys(formattedMobility).includes('student')
        && method !== null
        && submitUrl !== null
    ) {
        return (
            <Form
                data={formattedMobility}
                fields={Mobility}
                method={method}
                submitUrl={submitUrl}
                redirectOnSuccess={
                    mobilityResponse => {
                        refreshCurrentUserStudentMobilities()
                        return '/mobility/' + mobilityResponse.id
                    }
                }
                successMessage={successMessage}
            />
        )
    }
}