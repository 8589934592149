import React, {useContext} from 'react'
import { Nav } from 'react-bootstrap'

import {CalendarCheck, Gear, Plus} from "react-bootstrap-icons";

import {Link} from 'react-router-dom'
import {UserContext} from "../../utils/context/UserProvider";
import {prettifyDate} from "../../utils/Utils";
import {TimelineIndicator} from "../mobility/TimelineIndicator";

export const SidebarContent = () => {
  const {currentUserStudentMobilities, currentUserStudentHomeHei} = useContext(UserContext)

  return (
    <div className="d-flex flex-column align-items-center align-items-sm-start px-1 pt-2 text-black min-vh-100 mt-3">
      <div className={'text-muted'}>Home university</div>
      <h6>{currentUserStudentHomeHei !== null && currentUserStudentHomeHei.name}</h6>
      <Nav.Link as={Link} to={'/student/settings'} className="px-0 ps-2 py-2 text-black">
        <small className="d-sm-inline text-black"><Gear /> Profile</small>
      </Nav.Link>
      <Nav.Link as={Link} to={'/'} className="px-0 ps-2 py-2 text-black">
        <small className="d-sm-inline text-black"><CalendarCheck /> Mobilities ({currentUserStudentMobilities.length})</small>
      </Nav.Link>

      <Nav.Link as={Link} to={'/mobility_new'} className="text-muted sub-menu">
        <small className="d-sm-inline text-black">
          <div><Plus /> Create new</div>
        </small>
      </Nav.Link>

      {
        currentUserStudentMobilities.map(
            (mobility, index) =>
                <Nav.Link key={index} as={Link} to={'/mobility/' + mobility.id} className="text-black sub-menu">
                  <small className="d-sm-inline text-black">
                    <div>@{mobility.receivingHei.name}</div>
                    <div>
                      <TimelineIndicator mobility={mobility}/> <span className="text-muted">
                      {prettifyDate(mobility.startDate)} - {prettifyDate(mobility.endDate)}
                    </span>
                    </div>
                  </small>
                </Nav.Link>
        )
      }
    </div>
  )
}

