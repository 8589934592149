import React, {createContext, useEffect} from 'react'
import {useApi} from "../hooks/useApi";
import {LoadingSpinner} from "../../components/common/LoadingSpinner";

export const HeisContext = createContext([])

export function HeisProvider({ children }) {
    const {
        isLoaded: areHeisLoaded,
        data: heis,
        queryApi: heisQueryApi
    } = useApi(
        {
            url: '/api/heis',
            method: 'get',
            isCollection: true,
        }
    )

    const loadHeis = () => {
        heisQueryApi()
    }

    useEffect(() => {
        if (heis.length === 0) {
            loadHeis()
        }
    }, [])

    return (
        !areHeisLoaded?
            <LoadingSpinner/>
            :
            <HeisContext.Provider value={{heis, loadHeis}}>
                {children}
            </HeisContext.Provider>
    )
}