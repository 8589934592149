export const Course = [
    {
        label: 'Course ID',
        controlId: 'losCode',
        type: 'text',
    },
    {
        label: 'Title',
        controlId: 'title',
    },
    {
        label: 'Credits',
        controlId: 'credits',
        type: 'number',
        step: 0.01,
    },
    {
        label: 'Conditions to meet for recognition',
        controlId: 'recognitionConditions',
        type: 'textarea',
        required: false,
    },
    {
        label: 'Description',
        controlId: 'shortDescription',
        type: 'textarea',
        required: false,
    },
]