import React, { useEffect } from 'react'
import {Link, useParams} from "react-router-dom";
import {SendingHeiCoursesSelectionForm} from "../form/SendingHeiCoursesSelectionForm";
import {Alert} from "react-bootstrap";
import {Page} from "./Page";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

export const SendingHeiCoursesSelectionPage = () => {
    let { mobilityId } = useParams()

    const {
        errorNotFound: errorMobilityNotFound,
        isLoaded: isMobilityLoaded,
        data: mobility,
        queryApi: mobilityQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId,
            method: 'get',
        }
    )

    const {
        isLoaded: isLearningAgreementLoaded,
        data: learningAgreement,
        queryApi: learningAgreementQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/learning_agreement',
            method: 'get',
        }
    )

    useEffect(
        () => {
            mobilityQueryApi()
        }, [mobilityId]
    )

    useEffect(() => {
        if (
            mobility !== null
            && mobility.learningAgreement !== undefined
        ) {
            learningAgreementQueryApi()
        }
    }, [mobility])

    return (
        <Page
            breadcrumbs={
                mobility !== null
                && mobility.learningAgreement !== undefined ?
                    [
                        {label: 'Mobility', url: '/mobility/'+mobilityId},
                        {label: 'Learning agreement', url: '/mobility/' + mobilityId + '/learning_agreement'},
                        {label: 'Sending university courses selection', active: true},
                    ]
                    :
                    [
                        {label: 'Mobility', url: '/mobility/'+mobilityId},
                        {label: 'Sending university courses selection', active: true},
                    ]
            }
            title={'Courses selection at the sending university'}
        >
            {
                errorMobilityNotFound?
                    <Alert variant={'danger'}>
                        No mobility found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isMobilityLoaded?
                        <LoadingSpinner/>
                        :
                        !isLearningAgreementLoaded
                        && mobility.learningAgreement !== undefined ?
                            <LoadingSpinner/>
                            :
                            <SendingHeiCoursesSelectionForm
                                learningAgreement={
                                    learningAgreement === null?
                                        {
                                            mobility: mobility['@id'],
                                            student: mobility.student['@id']
                                        }
                                        :
                                        learningAgreement
                                }
                            />
            }
        </Page>
    )
}