import React, {useContext, useEffect, useState} from 'react'
import {Select} from "../base/Select";
import {HeisContext} from "../../../utils/context/HeisProvider";

export const SelectHei = ({controlId, label = 'University', placeholder = 'Select university',
                                 className = 'mb-3', irisToRestrict=[], irisToRemove = [], disabled= false}) => {

    const {heis} = useContext(HeisContext)
    const [options, setOptions] = useState([])

    const loadOptions = () => {
        let selectHeiList = []

        heis.forEach(
            (hei) => {
                let heiIri = hei['@id']
                // If we restrict options and this option is part of those, or we do not restrict options and this option is not part of the removed options
                if (
                    (
                        irisToRestrict.length > 0
                        && irisToRestrict.includes(heiIri)
                    ) || (
                        irisToRestrict.length === 0
                        && !irisToRemove.includes(heiIri)
                    )
                )
                selectHeiList.push(
                    {
                        value: heiIri,
                        label: hei.name
                    }
                )
            }
        )

        setOptions(selectHeiList)
    }

    useEffect(() => {
        if (heis.length !== 0) {
            loadOptions()
        }
    }, [heis])

    return <Select
        controlId={controlId}
        label={label}
        placeholder={placeholder}
        className={className}
        options={options}
        disabled={disabled}
    />
}