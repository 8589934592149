import React from 'react'
import {Link} from "react-router-dom";

export const Footer = () => {
  return (
      <footer className={'page-footer'}>
        <ul>
          {
            [
              {url: '/terms_and_conditions', label: 'Terms and conditions'},
              {url: '/privacy_policy', label: 'Privacy policy'},
              {url: '/cookies_policy', label: 'Cookies policy'},
            ].map(
                (link, index) =>
                    <li key={index + link.url}><Link to={link.url}>{link.label}</Link></li>
            )
          }
        </ul>

        &#169; All rights reserved to the ASEAN-SHARE program
      </footer>
  )
}
