import React from 'react'
import ReactSelect from "react-select" ;
import {Controller, get, useFormContext} from "react-hook-form";
import {FloatingLabel, Form} from "react-bootstrap";

export const Select = ({controlId, options= [], label, placeholder, required= true, disabled=false, className}) => {
    const { control, formState: {errors} } = useFormContext();

    const findValueInOptions = (value) => {
        for (let i=0 ; i< options.length; i++) {
            let option = options[i]

            // Handle optGroup case
            if (option.hasOwnProperty('options')) {
                let subOptions = option.options
                for (let j=0 ; j< subOptions.length; j++) {
                    let subOption = subOptions[j]

                    if (subOption.value === value) {
                        return subOption
                    }
                }
            } else if (option.value === value) {
                return option
            }
        }

        return undefined
    }

    return <FloatingLabel
        className={className || 'mb-3'}
        controlId={controlId}
        label={<span className={required? 'label-for-required': undefined}>{label}</span>}
    >
        <Controller control={control}
                    name={controlId}
                    render={({ field }) => (
                        <ReactSelect
                            className={get(errors, controlId)?'form-select floating-react-select is-invalid':'form-select floating-react-select'}
                            {...field}
                            value={findValueInOptions(field.value)}
                            onChange={val => field.onChange(val.value)}
                            placeholder={placeholder}
                            classNamePrefix={'floating-react-select'}
                            aria-label={label}
                            options={options}
                            isDisabled={disabled}
                            openMenuOnFocus={true}
                        />
                    )}
        />
        <Form.Control.Feedback type="invalid">
            {get(errors, controlId)?.message}
        </Form.Control.Feedback>
    </FloatingLabel>
}