export const ContactDetails = [
    {
        label: 'Given name(s)',
        controlId: 'givenNames',
        type: 'text',
    },
    {
        label: 'Family name(s)',
        controlId: 'familyNames',
        type: 'text',
    },
    {
        label: 'Email',
        controlId: 'email',
        type: 'email',
    },
    {
        label: 'Phone number',
        controlId: 'phoneNumber',
        type: 'tel',
    },
]