import React, { useContext } from "react"
import {UserContext} from '../../utils/context/UserProvider'
import {StudentSettingsForm} from "../form/StudentSettingsForm";
import {Page} from "./Page";

export const StudentSettingsPage = () => {
    const {currentUserStudent} = useContext(UserContext)

    return (
        <Page
            breadcrumbs={[
                {label: 'Profile', active: true},
            ]}
            title={'Update student profile'}
        >
            {currentUserStudent !== null &&
                <StudentSettingsForm
                    student={currentUserStudent}
                />
            }
        </Page>
    )
}
