import {Course} from "./Course";

export const CourseGrade = [
    {
        label: 'Did the student passed the class?',
        controlId: 'passed',
        type: 'checkbox',
        required: false,
    },
    {
        label: 'Grade',
        controlId: 'grade',
        required: false,
    },
    {
        colWrapperMd: 12,
        label: 'Course',
        controlId: 'course',
        type: Course,
    },
]