import React, {Fragment} from "react"
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";

export const SidebarNav = ({menuItems, indexDiscriminant}) => {
    return (
        <Nav className="flex-column my-2 align-items-center align-items-sm-start">
            {
                menuItems.map(
                    (menuItem, itemIndex) =>
                        <Fragment key={itemIndex + '-' + indexDiscriminant + '-menu-items'}>
                            {
                                menuItem.hasOwnProperty('link')?
                                    <Nav.Link as={Link} to={menuItem.link} className="px-0 ps-2 py-2 text-black">
                                        <small>{menuItem.content}</small>
                                    </Nav.Link>
                                    :
                                    <Nav.Link className="px-0 ps-2 py-2 text-black">
                                        <small>{menuItem.content}</small>
                                    </Nav.Link>
                            }
                        </Fragment>
                )
            }
        </Nav>
    )
}