import React from 'react'
import {TermsAndConditionsPage} from "../components/pages/TermsAndConditionsPage";
import {PrivacyPolicyPage} from "../components/pages/PrivacyPolicyPage";
import {CookiesPolicyPage} from "../components/pages/CookiesPolicyPage";

const routes = [
    {path: '/terms_and_conditions', element: <TermsAndConditionsPage />, protected: false},
    {path: '/privacy_policy', element: <PrivacyPolicyPage />, protected: false},
    {path: '/cookies_policy', element: <CookiesPolicyPage />, protected: false},
]

export default routes