import React, {useEffect, useState} from 'react'
import {Form} from "./base/Form";
import {TranscriptOfRecords} from "./type/TranscriptOfRecords";

export const TranscriptOfRecordsForm = ({transcriptOfRecords}) => {
    const [method, setMethod] = useState(null)
    const [submitUrl, setSubmitUrl] = useState(null)

    useEffect(() => {
        if (transcriptOfRecords) {
            if (transcriptOfRecords.id !== undefined) {
                setMethod('patch')
                setSubmitUrl(transcriptOfRecords["@id"])
            } else {
                setMethod('post')
                setSubmitUrl('/api/transcript_of_records')
            }
        } else {
            setMethod(null)
            setSubmitUrl(null)
        }
    }, [transcriptOfRecords])

    return (
        transcriptOfRecords !== undefined
        && method !== null
        && submitUrl !== null &&
            <Form
                fields={TranscriptOfRecords}
                data={transcriptOfRecords}
                method={method}
                submitUrl={submitUrl}
                successMessage="The transcript of records was created and notifications were sent to the student and the sending university."
                redirectOnSuccess={
                    transcriptOfRecordsResponse => {
                        return '/transcript_of_records/' + transcriptOfRecordsResponse.mobility.id
                    }
                }
            />
    )
}