import {useAuth} from "./utils/hooks/useAuth";
import {Navigate, useLocation, useNavigate} from "react-router-dom";
import {FlashBagContext} from "./utils/context/FlashBagProvider";
import {useContext, useEffect, useState} from "react";
import {UserContext} from "./utils/context/UserProvider";
import {LoadingSpinner} from "./components/common/LoadingSpinner";

export const Protected = ({ children }) => {
  const {isAuthenticated} = useAuth()
  const [settingsRoute, setSettingsRoute] = useState(null)
  const [accessDenied, setAccessDenied] = useState(false)
  const location = useLocation()
  const {addFlash} = useContext(FlashBagContext)
  const {mustCompleteProfile, currentUserStudent, currentUserStaff} = useContext(UserContext)
  const navigate = useNavigate()


  const checkProtection = () => {
    if (!isAuthenticated()) {
      addFlash({
        severity: 'warning',
        message: 'Please login to access the requested URL.',
        persistsForOneLocationChange: true
      })

      setAccessDenied(true)
    } else {
      setAccessDenied(false)

      if (
          settingsRoute !== null
          && mustCompleteProfile
          && location.pathname !== settingsRoute
      ) {
        addFlash({
          severity: 'warning',
          message: 'Please fill in your profile before being able to access the rest of the platform.',
          persistsForOneLocationChange: true
        })

        navigate(settingsRoute)
      }
    }
  }

  useEffect(() => {
    checkProtection()
  }, [])

  useEffect(() => {
    checkProtection()
  }, [location, settingsRoute])

  useEffect(() => {
    if (currentUserStudent !== null) {
      setSettingsRoute( '/student/settings')
    } else if (currentUserStaff !== null) {
      setSettingsRoute('/staff/settings')
    }
  }, [currentUserStaff, currentUserStudent])

  return (
      settingsRoute === null ?
          <LoadingSpinner/>
          :
          accessDenied?
              <Navigate to="/login" replace state={{ from: location }} />
              :
              children
  )
}