import React from 'react'
import {Select} from "../base/Select";
const iscedFCodes = require('../../../utils/iscedFCodes.json')

export const SelectIscedFCode = (props) => {

    return <Select
        {...props}
        label={props.label || 'ISCED F code'}
        placeholder={props.placeholder || 'Select ISCED F code'}
        className={props.className || 'mb-3'}
        options={iscedFCodes}
    />
}