import React, {useEffect} from 'react'
import {Page} from "./Page";
import {Alert} from "react-bootstrap";
import {Link, useParams} from "react-router-dom";
import {CreditsRecognition} from "../creditsRecognition/CreditsRecognition";
import {CreditsRecognition as CreditsRecognitionIcon} from "../icons/CreditsRecognition";
import {PdfButton} from "../creditsRecognition/PdfButton";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

export const CreditsRecognitionPage = () => {
    let { mobilityId } = useParams()

    const {
        errorNotFound: errorTranscriptOfRecordsNotFound,
        isLoaded: isTranscriptOfRecordsLoaded,
        data: transcriptOfRecords,
        queryApi: transcriptOfRecordsQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/transcript_of_records',
            method: 'get',
        }
    )

    useEffect(
        () => {
            transcriptOfRecordsQueryApi()
        }, [mobilityId]
    )

    return (
        <Page
            breadcrumbs={[
                {url: '/mobility/' + mobilityId, label: 'Mobility'},
                {label: 'Credits recognition', active: true},
            ]}
            title={<><CreditsRecognitionIcon/> Credits recognition</>}
        >
            {
                errorTranscriptOfRecordsNotFound ?
                    <Alert variant={'danger'}>
                        No transcript of records found with ID "{mobilityId}". <Link to={'/'}>Go back to your
                        mobilities overview</Link>.
                    </Alert>
                    :
                    !isTranscriptOfRecordsLoaded?
                        <LoadingSpinner/>
                        :
                        Object.keys(transcriptOfRecords).length !== 0 &&
                        <>
                            <p><small className="text-muted">[ ID: {mobilityId} ]</small></p>
                            <p>
                                <PdfButton mobility={transcriptOfRecords.mobility}/>
                            </p>

                            <CreditsRecognition transcriptOfRecords={transcriptOfRecords} />
                        </>
            }
        </Page>
    )
}