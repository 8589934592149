import React from 'react'

export const Blockquote = (props) => {
    const quote = props.quote
    const caption = props.caption

    return (
        <figure className="text-center">
            <blockquote className="blockquote">
                <p>"{quote}"</p>
            </blockquote>
            {
                (typeof caption !== 'undefined')?
                (
                    <figcaption className="blockquote-footer">
                        {caption}
                    </figcaption>
                )
                :('')
            }
        </figure>
    )
}