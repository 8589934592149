import React from 'react'
import {Link} from 'react-router-dom'
import { Row, Col, Card } from 'react-bootstrap'
import {BoxArrowInLeft, BoxArrowRight} from "react-bootstrap-icons";
import {OutgoingCounts} from "../learningAgreement/OutgoingCounts";
import {IncomingCounts} from "../learningAgreement/IncomingCounts";

export const Overview = ({hei}) => {
  return <Row>
      <Col className={'d-flex align-items-stretch'}>
        <Card className="d-flex flex-column justify-content-between">
          <Card.Header>
            <Card.Title as="h6">
              <Link to={'/university/'+hei.schacCode+'/outgoing_mobilities'}>
                <BoxArrowRight /> Outgoing mobilities
              </Link>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <h5 className="mb-4">Learning Agreements</h5>
            <OutgoingCounts
                hei={ hei }
            />
          </Card.Body>
        </Card>
      </Col>

      <Col className={'d-flex align-items-stretch'}>
        <Card className="d-flex flex-column justify-content-between">
          <Card.Header>
            <Card.Title as="h6">
              <Link to={'/university/'+hei.schacCode+'/incoming_mobilities'}>
                <BoxArrowInLeft /> Incoming mobilities
              </Link>
            </Card.Title>
          </Card.Header>
          <Card.Body>
            <h5 className="mb-4">Learning Agreements</h5>
            <IncomingCounts
                hei={ hei }
            />
          </Card.Body>
        </Card>
      </Col>
    </Row>
}
