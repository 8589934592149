import React, {useState, useEffect, useContext} from 'react'
import Select from "react-select";
import {Controller} from "react-hook-form";
import {HeisContext} from "../../../utils/context/HeisProvider";

export const SelectHeiFilter = ({inputName, control}) => {
    const {heis} = useContext(HeisContext)
    const [heiOptions, setHeiOptions] = useState([])

    const initHeiOptions = () => {
        let heiOptions = []

        heis.forEach(
            (hei) => heiOptions.push(
                {
                    value: hei['schacCode'],
                    label: hei.name
                }
            )
        )

        setHeiOptions(heiOptions)
    }

    useEffect(() => {
        if (heis.length !== 0) {
            initHeiOptions()
        }
    }, [heis])

    return <Controller
            name={inputName}
            control={control}
            render={({ field }) => <Select
                    {...field}
                    isClearable
                    placeholder={'Filter by receiving university'}
                    options={heiOptions}
                />}
            />
}