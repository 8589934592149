import {FlashBagContext} from "../../utils/context/FlashBagProvider";
import React, {useContext} from "react";
import {Alert} from "react-bootstrap";

export const FlashMessage = () => {
    const { flash } = useContext(FlashBagContext)

    return (
        flash &&
        <Alert
            variant={flash.severity}
        >
            {flash.message}
        </Alert>
    )
}