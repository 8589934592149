import React from 'react'
import {Col, Row} from 'react-bootstrap'
import {NavInPage} from "./NavInPage";

export const DynamicDocument = ({sections}) => {
    if(Object.keys(sections).length !== 0) {
        return (
            <Row>
                <Col md={2}>
                    <small><NavInPage sections={sections} /></small>
                </Col>
                <Col md={10}>
                    {
                        sections.map((item, index) =>
                            <div key={index}>
                                <h2 id={item.anchor}>{item.header}</h2>
                                {item.body}
                            </div>
                        )
                    }
                </Col>
            </Row>
        )
    }
}
