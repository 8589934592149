import React from 'react'
import {TranscriptOfRecordsPage} from "../components/pages/TranscriptOfRecordsPage";
import {TranscriptOfRecordsCreatePage} from "../components/pages/TranscriptOfRecordsCreatePage";
import {CreditsRecognitionPage} from "../components/pages/CreditsRecognitionPage";

const routes = [
	{path: '/transcript_of_records/:mobilityId', element: <TranscriptOfRecordsPage />},
	{path: '/transcript_of_records/:mobilityId/create', element: <TranscriptOfRecordsCreatePage />},
	{path: '/credits_recognition/:mobilityId', element: <CreditsRecognitionPage />},
]

export default routes