import React, {useContext, useEffect, useState} from 'react'
import {
    Button,
    OverlayTrigger,
    Popover,
    ListGroup, Col, Row,
} from 'react-bootstrap'
import {prettifyDate} from "../../utils/Utils";

import {BellFill, FileEarmarkText} from 'react-bootstrap-icons';
import {useNavigate} from "react-router-dom";
import {UserContext} from "../../utils/context/UserProvider";
import {useApi} from "../../utils/hooks/useApi";

export const Notifications = () => {
    const {notifications, refreshNotifications, unreadNotificationsCount} = useContext(UserContext)
    const [clickedNotification, setClickedNotification] = useState(null)

    let navigate = useNavigate()

    // Notification read
    const {
        queryApi: learningAgreementSubmitQueryApi
    } = useApi(
        {
            url: '/api/notifications/' + clickedNotification?.id + '/read',
            method: 'patch',
            thenCallback: () => {
                refreshNotifications()
            }
        }
    )

    useEffect(
        () => {
            if (clickedNotification !== null) {
                // Close notifications popover by simulating an outside click
                document.body.click()

                // Mark notification as "read"
                if (typeof clickedNotification.readAt === 'undefined'){
                    learningAgreementSubmitQueryApi()
                }

                // Navigate (or any other action) to appropriate page for notification
                switch (clickedNotification.notification.type) {
                    case 'la_submitted_by_student.sending_hei':
                    case 'la_accepted_by_sending.student':
                    case 'la_accepted_by_sending.receiving_hei':
                    case 'la_accepted_by_receiving.student':
                    case 'la_accepted_by_receiving.sending_hei':
                    case 'la_rejected_by_receiving.sending_hei':
                        navigate("/learning_agreement/" + clickedNotification.notification.data.mobilityId)
                        break
                    case 'la_rejected_by_sending.student':
                    case 'la_rejected_by_receiving.student':
                        navigate("/mobility/" + clickedNotification.notification.data.mobilityId + '/learning_agreement')
                        break
                    case 'tor_created_by_receiving.student':
                    case 'tor_created_by_receiving.sending_hei':
                    case 'tor_modified_by_receiving.student':
                    case 'tor_modified_by_receiving.sending_hei':
                        navigate("/transcript_of_records/" + clickedNotification.notification.data.mobilityId)
                        break
                    default:
                        alert('Notification type not supported')
                        break
                }

                setClickedNotification(null)
            }
        }, [clickedNotification]
    )

    const popover = (
        <Popover className="popover-notifications">
            <Popover.Header as="h4">Notifications {unreadNotificationsCount > 0 && <span>({unreadNotificationsCount})</span>}</Popover.Header>
            <Popover.Body>
                <ListGroup variant="flush">
                    {
                        JSON.parse(JSON.stringify(notifications)).reverse().map(
                            (notification, index) =>
                            <ListGroup.Item action active={typeof notification.readAt === 'undefined'} key={index} onClick={() => setClickedNotification(notification)}
                            >
                                <Row>
                                    <Col md={2} className={'d-flex align-items-center justify-content-center'}>
                                        <span className={'icon-container'}>
                                            <FileEarmarkText size={'1.5em'} />
                                        </span>
                                    </Col>
                                    <Col md={10}>
                                        {notification.notification.subject}
                                        <div>
                                            <small className={'text-muted float-end'}>
                                                {prettifyDate(notification.notification.sentAt)}
                                            </small>
                                        </div>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </Popover.Body>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" rootClose placement="bottom" overlay={popover}>
            <Button variant="light">
                <div className="position-relative">
                    <BellFill size={'1.5em'} />
                    {
                        (unreadNotificationsCount > 0) ?
                            (
                                <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">
                                    {unreadNotificationsCount}
                                    <span className="visually-hidden">New notifications</span>
                                </span>
                            ):
                            ('')
                    }
                </div>
            </Button>
        </OverlayTrigger>
    )
}