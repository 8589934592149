import React, {useContext, useEffect} from 'react'
import {Form} from "./base/Form";
import {StudentSettings} from "./type/StudentSettings";
import {useAuth} from "../../utils/hooks/useAuth";
import {UserContext} from "../../utils/context/UserProvider";

export const StudentSettingsForm = ({student}) => {
    const {tokenEmail} = useAuth()
    const {refreshCurrentUserStudent} = useContext(UserContext)

    const prefillEmailWithUserEmailIfEmpty = () => {
        if (!student.contactDetails) {
            student.contactDetails = {email: tokenEmail}
        } else if (!student.contactDetails.email) {
            student.contactDetails.email = tokenEmail
        }
    }

    useEffect(() => {
        prefillEmailWithUserEmailIfEmpty()
    }, [student])

    return (
        <Form
            fields={StudentSettings}
            data={student}
            method={'patch'}
            submitUrl={student["@id"]+"/settings"}
            redirectOnSuccess={
                () => {
                    refreshCurrentUserStudent()
                    return '/'
                }
            }
            successMessage={'Your settings were saved.'}
        />
    );
}