import React, {useContext, useEffect, useState} from "react"
import {Link} from "react-router-dom";
import {TranscriptOfRecords} from "../icons/TranscriptOfRecords";
import {UserContext} from "../../utils/context/UserProvider";
import {Button as BootstrapButton} from "react-bootstrap";

export const Button = ({mobility}) => {
    const [currentUserCanSubmitTranscriptOfRecords, setCurrentUserCanSubmitTranscriptOfRecords] = useState(false)
    const {currentUserStaffHeis} = useContext(UserContext)

    useEffect(() => {
        setCurrentUserCanSubmitTranscriptOfRecords(false)

        if (mobility !== null && mobility !== undefined ) {
            currentUserStaffHeis.forEach((hei) => {
                if (hei['@id'] === mobility.receivingHei['@id']) {
                    setCurrentUserCanSubmitTranscriptOfRecords(true)
                }
            })
        }
    }, [mobility, currentUserStaffHeis])

    return (
        mobility !== undefined &&
        (
            <>
                {
                    mobility.transcriptOfRecords === undefined ?
                        currentUserCanSubmitTranscriptOfRecords ?
                            <BootstrapButton as={Link} to={'/transcript_of_records/' + mobility.id + '/create'} variant={'primary'}>
                                <TranscriptOfRecords/> Create transcript of records
                            </BootstrapButton>
                            :
                            <BootstrapButton disabled variant={'outline-secondary'}>
                                <TranscriptOfRecords/> Transcript of records
                            </BootstrapButton>
                        :
                        <BootstrapButton as={Link} to={'/transcript_of_records/' + mobility.id} variant={'outline-secondary'}>
                            <TranscriptOfRecords/> Transcript of records
                        </BootstrapButton>
                }
            </>

        )
    )
}