import React from 'react'
import {Breadcrumb} from "../common/Breadcrumb";
import {FlashMessage} from "../common/FlashMessage";

export const Page = (props) => {
    return (
        <>
            <Breadcrumb
                breadcrumbs={props.breadcrumbs}
            />
            <FlashMessage/>
            <h1>{props.title}</h1>
            {props.children}
        </>
    )
}