import React from 'react'
import {Card, Nav} from "react-bootstrap";
import {Link45deg} from "react-bootstrap-icons";

export const NavInPage = ({ sections }) => {
    if(sections.length !== 0) {
        return <Card className="nav-in-page">
            <Card.Header><Link45deg/> Quick access</Card.Header>
            <Card.Body>
                <Nav defaultActiveKey={sections[0].anchor} className="flex-column">
                    {
                        sections.map(
                            (section, index) =>
                                <Nav.Link key={index} href={'#' + section.anchor}>{section.header}</Nav.Link>
                        )
                    }
                </Nav>
            </Card.Body>
        </Card>



    }
}