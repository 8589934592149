import React from "react"
import {Col, Row} from "react-bootstrap";

export const FormulasExplanation = () => {
    return (
        <>
            <Row className={'mt-3'}>
                <Col md={3}>
                    <strong><em>Course credits estimation</em></strong> =
                    <br/><small className={'text-muted'}>[<strong>@home</strong> university]</small>
                </Col>
                <Col md={9}>
                    <em>course credits <small>[<strong>@host</strong> university]</small></em>
                    <br/>x <em>coefficient of equivalence</em>
                </Col>
            </Row>
            <Row className={'mt-3 mb-3'}>
                <Col md={3}>
                    <strong><em>Coefficient of equivalence</em></strong> =
                    <br/><small className={'text-muted'}>(for estimation credit equivalence)</small>
                </Col>
                <Col md={9}>
                    <em>Total of credits required <small>[<strong>@home</strong> university]</small> for the student's degree</em>
                    <br/>/ <em>total of credits required <small>[<strong>@host</strong> university]</small> for the student's degree</em>
                </Col>
            </Row>
        </>
    )
}