import React from 'react'
import {Form} from "./base/Form";
import {ReceivingHeiCoursesSelection} from "./type/ReceivingHeiCoursesSelection";

export const ReceivingHeiCoursesSelectionForm = ({learningAgreement = {}}) => {
    let data = learningAgreement
    let postSubmitUrl = '/api/learning_agreements'

    let fieldsToSend = [
        {name: 'receivingHeiLearningOutcomesUrl'},
        {name: 'receivingCourses'},
        {name: 'mobility', asIri: true},
        {name: 'student', asIri: true},
    ]

    /*
        TODO The rest here is to be factorized
     */

    // Restrict values to expected fields to send (the data might have more fields)
    let defaultValues = {}
    fieldsToSend.forEach(
        field => {
            if (data.hasOwnProperty(field.name)) {
                if (
                    field.asIri
                ) {
                    defaultValues[field.name] = data[field.name]['@id']
                } else {
                    defaultValues[field.name] = data[field.name]
                }
            }
        }
    )

    let method
    let submitUrl

    if (data.id !== undefined) {
        method = 'patch'
        submitUrl = data["@id"]
    } else {
        method = 'post'
        submitUrl = postSubmitUrl
    }

    return (
        <Form
            fields={ReceivingHeiCoursesSelection}
            data={defaultValues}
            method={method}
            submitUrl={submitUrl}
            redirectOnSuccess={
                learningAgreementResponse => {
                    return '/mobility/' + learningAgreementResponse.mobility.id + '/learning_agreement'
                }
            }
            successMessage={'Your courses selection at the receiving university were saved.'}
        />
    );
}