import React from 'react'
import {SelectLanguage} from "./SelectLanguage";
import {SelectLanguageLevel} from "./SelectLanguageLevel";
import {FieldArray} from "../base/FieldArray";

export const LanguageLevelsFieldArray = (props) => {
    let prototype = {language: '', level: ''}

    let label = props.label || 'Language levels'

    return <FieldArray
        {...props}
        type={[
            {
                controlId: 'language',
                type: SelectLanguage
            },
            {
                controlId: 'level',
                type: SelectLanguageLevel
            },
        ]}
        prototype={prototype}
        label={ label }
    />
}