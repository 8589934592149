import React from 'react'
import {SelectCountry} from "./SelectCountry";

export const SelectCitizenship = (props) => {

    return <SelectCountry
        {...props}
        label={props.label || 'Citizenship'}
        placeholder={props.placeholder || 'Select citizenship'}
    />
}