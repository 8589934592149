import {
  gender, 
  learningAgreementStatusColorStudent, 
  status, 
  statusColorOutgoing, 
  eqfLevels,
} from './GlobalVars'

const countries = require('./countries.json')
const languages = require('./asean-languages.json')

export function arraysAreIdentical(arr1, arr2) {
  if (arr1.length !== arr2.length) return false 
  for (var i = 0, len = arr1.length; i < len; i++) {
    if (arr1[i] !== arr2[i]) {
      return false;
    }
  }
  return true; 
}

export function filterArrayByIDAndType(array) {
  const allowed = ['id', 'type']
  let filteredArray = []
  array.forEach(item => {
    const filtered = allowed.reduce((obj, key) => {
      obj[key] = item[key]
      return obj 
    }, {})
    filteredArray.push(filtered)
  })
  
  return filteredArray
}

export function filterArrayByIDAndLabel(array) {
  const allowed = ['id', 'attributes']
  let filteredArray = []
  array.forEach(item => {
    const filtered = allowed.reduce((obj, key) => {
      if(key === "attributes") {
        obj["label"] = item[key].label
      } else {
        obj["value"] = item[key]
      }
      return obj 
    }, {})
    filteredArray.push(filtered)
  })
  
  return filteredArray
}

export function filterArrayByID(array) {
  const allowed = ['id']
  let filteredArray = []
  array.forEach(item => {
    const filtered = allowed.reduce((obj, key) => {
      obj[key] = item[key]
      return obj 
    }, {})
    filteredArray.push(filtered)
  })
  
  return filteredArray
}

export function filterArrayByKey(array, key) {
  const allowed = [key]
  let filteredArray = []
  array.forEach(item => {
    const filtered = allowed.reduce((obj, key) => {
      obj[key] = item[key]
      return obj 
    }, {})
    filteredArray.push(filtered)
  })
  
  return filteredArray
}

export function itemInArray(array, item) {
  return array.map(i => i.id).includes(item)
}

export function prettifyDate(date) {
  const newDate = new Date(date)

  const locale = new Intl.NumberFormat().resolvedOptions().locale
  return new Intl.DateTimeFormat(locale).format(newDate)
}

export function formatDate(date) {
  const newDate = new Date(date)
  const day = newDate.getDate()
  const month = newDate.getMonth() + 1
  const year = newDate.getFullYear()

  return year + "-" + month + "-" + day
}

export function fromISOtoNormalDate(dateStr) {
  return dateStr.split("T")[0]
}

export function parseGenderData(genderInfo) {
  return gender[genderInfo]
}

export function parseStatusData(statusInfo) {
  return status[statusInfo]
}

export function getStatusData() {
  return status
}

export function parseStatusColorOutgoing(status) {
  return statusColorOutgoing[status]
}

export function parseLearningAgreementStatusColorStudent(status) {
  return learningAgreementStatusColorStudent[status]
}

export function getCitizenship(countryCode) {
  let citizenship = ""

  for (const country of countries) {
    if(country.alpha_2_code === countryCode) {
      citizenship = country.nationality
    }
  } 
  return citizenship
}

export function createCitizenshipLabelValuePair(countryCode) {
  let newObj = {}

  for (const country of countries) {
    if(country.alpha_2_code === countryCode) {
      newObj = {
        value: country.alpha_2_code,
        label: country.en_short_name
      }
    }
  } 
  return newObj
}

export function createArrayOfPropertyPath(arr) {
  let newArray = []

  arr.forEach((item) => {
    newArray.push(item.propertyPath)
  })

  return newArray
}

export function createSetNotFoundKeys(originalArr, lookupArr) {
  let notFoundArr = []

  originalArr.forEach((originalItem) => {
    if(!lookupArr.includes(originalItem)) {
      notFoundArr.push(originalItem)
    }
  })

  return notFoundArr
}

export function createStatusLabelValuePair() {
  let newArray = []
  Object.keys(status).forEach((key) => {
    if(key !== "draft"){
      newArray.push(
        {
          value: key,
          label: status[key]
        }
      )
    }
  })

  return newArray
}

export function createGenderLabelValuePair() {
  let newArray = []
  Object.keys(gender).forEach((key) => {
    newArray.push(
      {
        value: parseInt(key),
        label: gender[key]
      }
    )
  })

  return newArray
}

export function createEQFLevelLabelValuePair() {

  let labelValue = []
  Object.keys(eqfLevels).forEach((key) => {
    labelValue.push(
      {
        value: parseInt(key),
        label: eqfLevels[key]
      }
    )
  })

  return labelValue
}

/*export function createLanguageLevelLabelValuePair() {
  let labelValue = []
  Object.entries(languageLevels).forEach((pair) => {
    labelValue.push(
      {
        value: pair[0],
        label: pair[1]
      }
    )
  })

  return labelValue
}*/

export function createLabelValuePair(array) {
  let labelValue = []
  array.forEach((item) => {
    labelValue.push(
      {
        value: item.schacCode,
        label: item.name
      }
    )
  })

  return labelValue
}

export function createParamStringFromFormData(obj) {
  let paramStr = ""

  if(obj) {
    for (const [key, value] of Object.entries(obj)) {
      if(value.length !== 0)
        paramStr += key + "=" + value + "&"
    }
  }

  return paramStr
}

export function removeLastChar(str, element) {
  let newStr = ""

  if(str.charAt(str.length-1) === element) {
    newStr = str.slice(0, -1)
  }

  return newStr
}

export function createLanguageLevelsList() {
  let languageLevelSelect = []
  let levels = ['A1','A2','B1','B2','C1','C2']

  languages.forEach(language => {
    
    levels.forEach(level => {
      
      languageLevelSelect.push(
        {
          value: {
            language: language.code,
            level: level
          },
          label: level.toUpperCase() + " - " + language.name
        }
      )
      
    })
  })

  return languageLevelSelect
}

export function createLanguageList() {
  let languageSelect = []

  languages.forEach(language => {
    languageSelect.push(
        {
          value: language.code,
          label: language.name
        }
      )
  })

  return languageSelect
}

export function getLangNameFromISOCode(code) {
  let langName = ""
  languages.forEach(lang => {
    if(lang.code === code) {
      langName = lang.name
    }
  })

  return langName
}

export function createLanguageLevelLabelValuePair(languageLevels) {
  let newArray = []

  languageLevels.forEach(lang => {
    newArray.push(
      {
        value: {
          language: lang.language,
          level: lang.level
        },
        label: lang.level.toUpperCase() + " - " + getLangNameFromISOCode(lang.language)
      }
    )
  })

  return newArray
}

export function getTotalCreditsFromCourses(array) {
  let total = 0
  let filteredArray = filterArrayByKey(array, "credits")
  filteredArray.forEach(number => {
    total += number.credits
  })

  return total
}
