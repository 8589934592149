import React from 'react'
import {Controller, useFormContext, get} from "react-hook-form";
import {FloatingLabel, Form} from "react-bootstrap";
import {fromISOtoNormalDate} from "../../../utils/Utils";

export const Field = (props) => {
    let required = props.required === undefined || props.required
    const { control, formState: {errors} } = useFormContext();

    return ['checkbox', 'radio', 'switch'].includes(props.type) ?
        <>

            <Controller control={control}
                        name={props.controlId}
                        render={({ field }) => (
                            <Form.Check
                                {...field}
                                checked={field.value}
                                id={props.controlId}
                                control={control}
                                type={props.type}
                                label={<span className={required? 'label-for-required': undefined}>{props.label}</span>}
                                required={required}
                            />
                        )}
            />

            <Form.Control.Feedback type="invalid">
                {get(errors, props.controlId)?.message}
            </Form.Control.Feedback>
        </>
        :
        <FloatingLabel
            className={props.className || 'mb-3'}
            controlId={props.controlId}
            label={<span className={required? 'label-for-required': undefined}>{props.label}</span>}
        >
            <Controller control={control}
                        name={props.controlId}
                        render={({ field }) => (
                            props.type === 'textarea' ?
                                <Form.Control
                                    {...field}
                                    as={'textarea'}
                                    placeholder={props.label}
                                />
                                :
                                <Form.Control
                                    {...field}
                                    onChange={
                                        props.type === 'number' ?
                                            (event) => {
                                                let value = event.target.value

                                                // Fix error when a non-numerical value is entered
                                                if (value === '') {
                                                    value = 0
                                                }

                                                field.onChange(parseFloat(value));
                                            }
                                            :field.onChange
                                    }
                                    value={
                                        props.type === 'number' && field.value === null?
                                            0
                                            :
                                            field.value === undefined ?
                                                props.type === 'number' ?
                                                    0
                                                    :
                                                    ''
                                                :
                                                props.type === 'date' ?
                                                    fromISOtoNormalDate(field.value)
                                                    :
                                                    field.value
                                    }
                                    step={props.step === undefined ? 1: props.step}
                                    type={props.type}
                                    placeholder={props.label}
                                    isInvalid={get(errors, props.controlId)}
                                />

                        )}
            />
            <Form.Control.Feedback type="invalid">
                {get(errors, props.controlId)?.message}
            </Form.Control.Feedback>
        </FloatingLabel>
}