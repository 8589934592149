import React, {useContext, useEffect} from 'react'
import {SelectHei} from "./SelectHei";
import {UserContext} from "../../../utils/context/UserProvider";
import {useFormContext} from "react-hook-form";

export const SelectSendingHei = (props) => {
    const {currentUserStudent} = useContext(UserContext)
    const { setValue } = useFormContext();

    useEffect(() => {
        if (currentUserStudent !== null) {
            setValue(props.controlId, currentUserStudent.homeHei)
        }
    }, [currentUserStudent])


    if (currentUserStudent !== null) {
        return <SelectHei
            {...props}
            label={props.label || 'Receiving university'}
            placeholder={props.placeholder || 'Select receiving university'}
            disabled={props.disabled || false}//TODO change to true when figured out bug with setValue
            irisToRestrict={[currentUserStudent.homeHei]}
        />
    }
}