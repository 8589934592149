import React, { useContext } from "react"
import {UserContext} from '../../utils/context/UserProvider'
import {Page} from "./Page";
import {StaffSettingsForm} from "../form/StaffSettingsForm";

export const StaffSettingsPage = () => {
    const {currentUserStaff} = useContext(UserContext)

    return (
        <Page
            breadcrumbs={[
                {label: 'Profile', active: true},
            ]}
            title={'Update profile'}
        >
            {currentUserStaff !== null &&
                <StaffSettingsForm
                    staff={currentUserStaff}
                />
            }
        </Page>
    )
}
