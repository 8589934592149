import React from 'react'
import {Badge, Col, Row} from 'react-bootstrap'
import { PieChart } from 'react-minimal-pie-chart';

export const Counts = ({counts}) => {

  if (counts != null) {
    const chartData = [];

    counts.forEach(function(count){
      chartData.push({
        title: count.label,
        value: count.count,
        color: count.color,
      })
    })

    const labelStyle = {
      fontFamily: 'sans-serif',
      fontSize: '7px',
      fontWeight: 'bold',
    };

    return (
        <>
          <Row>
            <Col>
              <PieChart
                  label={({ dataEntry }) => dataEntry.value === 0 ? '' : dataEntry.value  }
                  data={chartData}
                  labelStyle={{
                    ...labelStyle,
                  }}
                  startAngle={180}
                  lengthAngle={180}
                  viewBoxSize={[100, 50]}
                  style={{ height: '150px' }}
              />
            </Col>
            <Col>
              {
                counts.map((count, index) =>
                    <p key={index}>
                      <Badge className="me-2" bg={""} style={{ backgroundColor: count.color}}>
                        { count.count }
                      </Badge>
                      { count.label }
                    </p>
                )
              }
            </Col>
          </Row>
        </>
    )
  }
}