import React, {Fragment, useContext, useEffect, useState} from 'react'
import {FormField} from "../base/Form";
import {Badge, Col, Row} from "react-bootstrap";
import {useFormContext} from "react-hook-form";
import {CreditsCoefficientContext} from "../../../utils/context/CreditsCoefficientProvider";

export const CourseCreditsEquivalence = (props) => {
    const {creditsCoefficient} = useContext(CreditsCoefficientContext)
    let controlId = props.controlId
    const [course, setCourse] = useState(null)

    const { getValues, setValue } = useFormContext();

    useEffect(() => {
        if (controlId !== null && controlId !== undefined) {
            let isolatedCourseId = controlId.split('.', 1)[0]

            let courseData = getValues(isolatedCourseId)

            let creditsEquivalenceEstimation = getValues(isolatedCourseId + '.credits') * creditsCoefficient
            courseData.creditsEquivalenceEstimation = creditsEquivalenceEstimation

            setCourse(courseData)
            setValue(controlId, creditsEquivalenceEstimation)
        }
    }, [props.controlId, creditsCoefficient])

    return <>
        {
            course !== null &&
            <>
                <h5>Title: {course.title}</h5>
                <p>
                    <small className={'text-muted'}>[ ID: {course.losCode} ]</small>
                </p>
                <Row as={'dl'}>
                    {
                        [
                            {
                                title: 'Description provided by student',
                                data: course.shortDescription || <em className={'text-muted'}>Not provided</em>,
                            },
                            {
                                title: 'Conditions of recognition',
                                data: course.recognitionConditions || <em className={'text-muted'}>Not provided</em>,
                            },
                            {
                                title: 'Credits at receiving university',
                                data: <Badge bg={'secondary'}>{course.credits}</Badge>,
                            },
                            {
                                title: 'Estimation using coefficient of equivalence',
                                data: <Badge bg={'success'}>{course.creditsEquivalenceEstimation}</Badge>,
                            },
                        ].map(
                            (data, indexCourse) =>
                                <Fragment key={ 'course-' + course.losCode + indexCourse }>
                                    <Col as={'dt'} md={6}>{data.title}</Col>
                                    <Col as={'dd'} md={6}>{data.data}</Col>
                                </Fragment>
                        )
                    }
                </Row>
                <FormField
                    field={{
                        label: 'Credits equivalence in your university\'s system',
                        colWrapperMd: 6,
                        controlId: controlId,
                        type: 'number',
                        step: 0.01,
                    }}
                />
            </>
        }

    </>
}