import {SelectEqfLevel} from "./SelectEqfLevel";
import {ContactDetails} from "./ContactDetails";
import {SelectCountry} from "./SelectCountry";
import {SelectIscedFCode} from "./SelectIscedFCode";
import {CreditsForGraduation} from "./CreditsForGraduation";

export const HeiSettings = [
    {
        label: 'Name',
        controlId: 'name',
    },
    {
        label: 'Website',
        controlId: 'website',
    },
    {
        label: 'Country',
        controlId: 'country',
        type: SelectCountry,
    },
    {
        colWrapperMd: 12,
        before: <h3>Credits equivalence/conversion settings</h3>,
        label: 'Credits required for graduation',
        controlId: 'creditsForGraduation',
        type: CreditsForGraduation,
    },
    {
        colWrapperMd: 12,
        label: 'Default mobility contact details',
        controlId: 'defaultMobilityContactDetails',
        type: ContactDetails,
    },
    {
        before: <h4 className="mb-4">Default data to pre-fill all new learning agreement with</h4>,
        label: 'Default mobility ISCED F Code',
        controlId: 'defaultMobilityIscedFCode',
        type: SelectIscedFCode,
        required: false,
    },
    {
        label: 'Default mobility ISCED F Code Clarification',
        controlId: 'defaultMobilityIscedClarification',
        type: 'textarea',
        required: false,
    },
    {
        label: 'Default learning agreement "provisions if incomplete" URL',
        controlId: 'defaultLAProvisionsIfIncompleteUrl',
        type: 'url',
        required: false,
    },
    {
        label: 'Default learning agreement "learning outcomes" as receiving university URL',
        controlId: 'defaultLALearningOutcomesUrlAsReceiving',
        type: 'url',
        required: false,
    },
    {
        label: 'Default mobility EQF level at departure',
        controlId: 'defaultMobilityEqfLevelAtDeparture',
        type: SelectEqfLevel,
        required: false,
    },
]