import React from 'react'
import {Page} from "./Page";

export const PrivacyPolicyPage = () => {

    return (
        <Page
            breadcrumbs={[
                {label: 'Privacy policy', active: true},
            ]}
            title={'Privacy policy'}
        >
            <p>
                To be completed...
            </p>
        </Page>
    )
}