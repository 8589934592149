import React, {useContext} from 'react'
import {Button,  Col, Row} from 'react-bootstrap'
import {Link} from "react-router-dom";
import {PlusLg} from "react-bootstrap-icons";
import {UserContext} from "../../utils/context/UserProvider";
import {Page} from "../pages/Page";
import {MobilityCard} from "../mobility/MobilityCard";

export const StudentDashboard = () => {
    const {currentUserStudent, currentUserStudentMobilities} = useContext(UserContext)

    if (currentUserStudent !== null) {
        return (
            <Page
                breadcrumbs={[
                    {label: 'Mobilities', active: true},
                ]}
                title={'My mobilities'}
            >
                <div className={'mb-3'}>
                    <Button variant={'primary'}>
                        <Link to={'/mobility_new'} className="text-light text-decoration-none"><PlusLg /> Create new mobility</Link>
                    </Button>
                </div>
                <Row>
                    {
                        currentUserStudentMobilities.map(
                            (mobility, index) =>
                                <Col md={4} key={index}>
                                    <MobilityCard mobility={mobility}/>
                                </Col>
                        )
                    }
                </Row>
            </Page>
        )
    }
}