import React from 'react'
import {Course} from "./Course";
import {FieldArray} from "../base/FieldArray";

export const CourseFieldArray = (props) => {
    let label = props.label || 'Courses'

    let prototype = {
        'losCode': '',
        'title': '',
        'credits': 0,
        'recognitionConditions': '',
        'shortDescription': '',
        'originatingFromLearningAgreement': true
    }

    return <FieldArray
        {...props}
        type={Course}
        prototype={prototype}
        label={ label }
    />
}