import React, {useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import {Page} from "./Page";
import {TranscriptOfRecords as TranscriptOfRecordsIcon} from "../icons/TranscriptOfRecords";
import {TranscriptOfRecords} from "../transcriptOfRecords/TranscriptOfRecords";
import {PdfButton} from "../transcriptOfRecords/PdfButton";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

export const TranscriptOfRecordsPage = () => {
    let { mobilityId } = useParams()

    const {
        errorNotFound: errorTranscriptOfRecordsNotFound,
        isLoaded: isTranscriptOfRecordsLoaded,
        data: transcriptOfRecords,
        queryApi: transcriptOfRecordsQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/transcript_of_records',
            method: 'get',
        }
    )

    useEffect(
        () => {
            transcriptOfRecordsQueryApi()
        }, [mobilityId]
    )

    return (
        <Page
            breadcrumbs={[
                {url: '/mobility/' + mobilityId, label: 'Mobility'},
                {label: 'Transcript of records', active: true},
            ]}
            title={<><TranscriptOfRecordsIcon/> Transcript of records</>}
        >
            {
                errorTranscriptOfRecordsNotFound ?
                    <Alert variant={'danger'}>
                        No transcript of records found with ID "{mobilityId}". <Link to={'/'}>Go back to your
                        mobilities overview</Link>.
                    </Alert>
                    :
                    !isTranscriptOfRecordsLoaded?
                        <LoadingSpinner/>
                        :
                        Object.keys(transcriptOfRecords).length !== 0 &&
                        <>
                            <p><small className="text-muted">[ ID: {mobilityId} ]</small></p>
                            <div className={'mb-3'}>
                                <PdfButton transcriptOfRecords={transcriptOfRecords}/>
                            </div>

                            <TranscriptOfRecords transcriptOfRecords={transcriptOfRecords}/>
                        </>
            }
        </Page>
    )
}
