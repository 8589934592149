import React, {useEffect, useState} from 'react'
import {Counts} from "../Counts";

export const OutgoingCounts = ({hei, handleClick}) => {

    const [counts, setCounts] = useState([])
    const calculateCounts = (hei) => {
        let requiresOwnActionCount = 0
        let actionPerformedCount = 0
        let requiresStudentActionCount = 0
        let completeCount = 0

        let statusCounts = hei.counts['outgoing']
        if (statusCounts != null) {
            Object.keys(statusCounts).forEach((status)  =>
                {
                    if( status === 'rejected_by_sending'
                        || status === 'rejected_by_receiving'
                    ) {
                        requiresStudentActionCount += statusCounts[status]
                    } else if(
                        status === 'submitted_by_student'
                    ) {
                        requiresOwnActionCount += statusCounts[status]
                    } else if(
                        status === 'accepted_by_receiving'
                    ) {
                        completeCount += statusCounts[status]
                    }else if(
                        status === 'accepted_by_sending'
                    ) {
                        actionPerformedCount += statusCounts[status]
                    }
                }
            )
        }

        setCounts([
            {label: 'Require(s) your action', color: 'red', count: requiresOwnActionCount},
            {label: 'Pending on receiving university', color: 'lightblue', count: actionPerformedCount},
            {label: 'Pending on student', color: 'orange', count: requiresStudentActionCount},
            {label: 'Complete', color: 'green', count: completeCount},
        ])
    }

    useEffect(() => {
        calculateCounts(hei)
    },[hei])

    return (
        <Counts
            counts={ counts }
            handleClick={handleClick}
        />
    )
}