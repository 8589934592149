import React from 'react'
import {Select} from "../base/Select";
import {languageLevels} from "../../../utils/GlobalVars";

export const SelectLanguageLevel = ({controlId, label = 'Level', placeholder = 'Select level',
                                 className = 'mb-3'}) => {

    let options = []
    Object.keys(languageLevels).forEach(
        (value) => {
            options.push({value: value, label: languageLevels[value]})
        }
    )

    return <Select
        controlId={controlId}
        label={label}
        placeholder={placeholder}
        className={className}
        options={options}
    />
}