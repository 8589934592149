import React, {useContext, useEffect, useState} from 'react'
import {Alert, Button, ListGroup} from 'react-bootstrap'
import {CheckCircleFill, XCircle} from "react-bootstrap-icons";
import {Link, useNavigate, useParams} from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import {Page} from "./Page";
import {Blockquote} from "../common/Blockquote";
import {RejectionAlert} from "../learningAgreement/RejectionAlert";
import {Status} from "../learningAgreement/Status";
import {FlashBagContext} from "../../utils/context/FlashBagProvider";
import {Flow} from "../learningAgreement/Flow";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";
import {PdfButton} from "../learningAgreement/PdfButton";


export const LearningAgreementOverviewPage = () => {
    let { mobilityId } = useParams()

    // Get Learning agreement
    const {
        errorNotFound: errorLearningAgreementNotFound,
        isLoaded: isLearningAgreementLoaded,
        data: learningAgreement,
        queryApi: learningAgreementQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/learning_agreement',
            method: 'get',
        }
    )

    // Get mobility
    const {
        errorNotFound: errorMobilityNotFound,
        isLoaded: isMobilityLoaded,
        data: mobility,
        queryApi: mobilityQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId,
            method: 'get',
        }
    )

    // Learning agreement submit
    const {
        queryApi: learningAgreementSubmitQueryApi
    } = useApi(
        {
            url: '/api/learning_agreements/' + learningAgreement?.id + '/student/submit',
            method: 'patch',
            thenCallback: () => {
                handleCloseConfirmAcceptModal()
                addFlash({
                    severity: 'success',
                    message: 'Your learning agreement has been submitted. Your home university received a notification, we will contact you when an action happens on their side.',
                    persistsForOneLocationChange: true
                })
                navigate('/learning_agreement/' + mobilityId)
            }
        }
    )

    const [mobilityStepComplete, setMobilityStepComplete] = useState(false)
    const [studentStepComplete, setStudentStepComplete] = useState(true)//TODO
    const [sendingCoursesStepComplete, setSendingCoursesStepComplete] = useState(false)
    const [receivingCoursesStepComplete, setReceivingCoursesStepComplete] = useState(false)
    const [isDraftStatus, setIsDraftStatus] = useState(false)
    const [submittable, setSubmittable] = useState(false)
    const [showConfirmAcceptModal, setShowConfirmAcceptModal] = useState(false);

    let navigate = useNavigate()
    const { addFlash } = useContext(FlashBagContext)

    // Load mobility according to URL parameters
    useEffect(() => {
        if ( mobilityId !== null ) {
            mobilityQueryApi()
        }
    }, [mobilityId])

    useEffect(() => {
        if (mobility !== null) {
            let learningAgreementIri = mobility.learningAgreement
            if (
                learningAgreementIri !== undefined
                && learningAgreementIri !== null
            ) {
                learningAgreementQueryApi()
            }

            setMobilityStepComplete(true)
        }
    }, [mobility])

    useEffect(() => {
        updateCoursesStepsCompletionStatus()
        setIsDraftStatus(
            learningAgreement !== null
            && learningAgreement.status === 'draft'
        )
    }, [learningAgreement])

    useEffect(() => {
        setSubmittable(
            sendingCoursesStepComplete
            && receivingCoursesStepComplete
        )
    }, [
        sendingCoursesStepComplete,
        receivingCoursesStepComplete,
    ])

    let steps = [
        {
            title: <>Check and confirm mobility details</>,
            url: '/mobility/' + mobilityId,
            done: mobilityStepComplete,
        },
        {
            title: <>Check and confirm your personal details</>,
            url: '/student/settings',
            done: studentStepComplete,
        },
        {
            title: <>Indicate the courses supposed to be followed at your home university</>,
            url: '/mobility/' + mobilityId + '/learning_agreement_sending_courses',
            done: sendingCoursesStepComplete,
        },
        {
            title: <>Select the courses you want to follow at your receiving university</>,
            url: '/mobility/' + mobilityId + '/learning_agreement_receiving_courses',
            done: receivingCoursesStepComplete,
        },
    ]

    let completedStepsCount = 0;
    steps.forEach(
        (step) => {
            if (step.done) {
                completedStepsCount++
            }
        }
    )

    const updateCoursesStepsCompletionStatus = () => {
        let learningAgreementHasAtLeastOneSendingCourse = learningAgreement !== null
            && learningAgreement.sendingCourses !== undefined
            && learningAgreement.sendingCourses.length > 0
        setSendingCoursesStepComplete(learningAgreementHasAtLeastOneSendingCourse)

        let learningAgreementHasAtLeastOneReceivingCourse = learningAgreement !== null
            && learningAgreement.receivingCourses !== undefined
            && learningAgreement.receivingCourses.length > 0
        setReceivingCoursesStepComplete(learningAgreementHasAtLeastOneReceivingCourse)
    }

    const submitLearningAgreement = () => {
        if (submittable) {
            learningAgreementSubmitQueryApi()
        } else {
            alert('Complete the steps before trying to submit')
        }
    }

    const handleCloseConfirmAcceptModal = () => {
        setShowConfirmAcceptModal(false);
    }
    const handleShowConfirmAcceptModal = () => {
        setShowConfirmAcceptModal(true);
    }

    return (
        <Page
            breadcrumbs={
                learningAgreement === null || learningAgreement.status === 'draft' ?
                    [
                        {url: '/mobility/' + mobilityId, label: 'Mobility'},
                        {label: 'Create learning agreement', active: true},
                    ]
                    :
                    [
                        {url: '/mobility/' + mobilityId, label: 'Mobility'},
                        {url: '/learning_agreement/' + mobilityId, label: 'Learning agreement'},
                        {label: 'Edit learning agreement', active: true},
                    ]
            }
            title={learningAgreement === null || learningAgreement.status === 'draft' ? 'Create new Learning Agreement' : 'Edit learning agreement'}
        >
            {
                errorMobilityNotFound ?
                    <Alert variant={'danger'}>
                        No mobility found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isMobilityLoaded?
                        <LoadingSpinner/>
                        :
                        errorLearningAgreementNotFound ?
                            <Alert variant={'danger'}>
                                No learning agreement found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                            </Alert>
                            :
                            !isLearningAgreementLoaded
                            && mobility.learningAgreement !== undefined ?
                                <LoadingSpinner/>
                                :
                                <>
                                    <p>
                                        {
                                            learningAgreement !== null
                                            && <Status learningAgreement={learningAgreement} />
                                        } <small className={'text-muted'}>[ ID: {mobilityId} ]</small>
                                    </p>

                                    {
                                        learningAgreement !== null &&
                                            <>
                                                {
                                                    learningAgreement.pdf !== undefined &&
                                                    <div className={'mb-3'}>
                                                        <PdfButton learningAgreement={learningAgreement}/>
                                                    </div>
                                                }

                                                <Flow learningAgreement={learningAgreement}/>

                                                <RejectionAlert
                                                    learningAgreement={learningAgreement}
                                                    mode={'student'}
                                                />
                                            </>
                                    }


                                    {
                                        mobilityStepComplete &&
                                        <>
                                            <ListGroup>
                                                <ListGroup.Item variant="light">
                                                    {completedStepsCount} / {steps.length} step(s) completed
                                                </ListGroup.Item>
                                                {
                                                    steps.map(
                                                        (step, index) =>
                                                            <ListGroup.Item variant={step.done? 'success': 'warning'} key={index} action as={Link} to={step.url}>
                                                                {step.done? <CheckCircleFill />: <XCircle/>} <strong>[Step {index + 1}]</strong> {step.title}
                                                            </ListGroup.Item>
                                                    )
                                                }
                                            </ListGroup>
                                            <div className="mt-3">
                                                {
                                                    submittable ?
                                                        isDraftStatus ?
                                                            <Button onClick={handleShowConfirmAcceptModal}>Submit learning agreement</Button>
                                                            :<Button onClick={handleShowConfirmAcceptModal}>Submit modified learning agreement</Button>
                                                        :isDraftStatus &&
                                                        <><Button disabled>Submit learning agreement</Button> Complete all {steps.length} steps to be able to submit the learning agreement.</>
                                                }
                                            </div>
                                        </>
                                    }

                                    <Modal show={showConfirmAcceptModal} onHide={handleCloseConfirmAcceptModal}>
                                        <Modal.Header closeButton>
                                            <Modal.Title>Submit learning agreement</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <Alert variant={"warning"}>
                                                <div>
                                                    <p>
                                                        You are about to submit your learning agreement.
                                                    </p>

                                                    {
                                                        learningAgreement !== null
                                                        && (
                                                            learningAgreement.status === 'rejected_by_sending'
                                                            || learningAgreement.status === 'rejected_by_receiving'
                                                        ) &&
                                                        <>
                                                            <p>
                                                                Your <strong>{learningAgreement.status === 'rejected_by_sending' ? 'home': 'receiving'} university</strong> had previously rejected your learning agreement for the following reasons:
                                                            </p>
                                                            <Blockquote
                                                                quote={(learningAgreement.status === 'rejected_by_sending')? (learningAgreement.rejectionSendingComment):(learningAgreement.rejectionReceivingComment)}
                                                            />
                                                            <p>
                                                                Please make sure that you followed their directions before submitting once again.
                                                            </p>
                                                        </>
                                                    }

                                                    <p>
                                                        This action is irreversible and will trigger a notification to the staff users at your home university for review.
                                                    </p>

                                                    <p><strong>Do you want to proceed?</strong></p>
                                                </div>
                                            </Alert>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Button variant="secondary" onClick={handleCloseConfirmAcceptModal}>
                                                Cancel
                                            </Button>
                                            <Button variant="primary" onClick={submitLearningAgreement}>
                                                Confirm
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </>
            }
        </Page>
    )
}
