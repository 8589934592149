import React, {createContext, useEffect, useState} from 'react'
import {useLocation} from "react-router-dom";

export const FlashBagContext = createContext([])

export function FlashBagProvider({ children }) {
    const [flash, setFlash] = useState(null)
    const [persistsForOneLocationChange, setPersistsForOneLocationChange] = useState(false)
    const location = useLocation()

    const addFlash = ({message, severity, persistsForOneLocationChange = false}) => {
        setPersistsForOneLocationChange(persistsForOneLocationChange)
        setFlash({message: message, severity: severity})
    }

    useEffect(() => {
        if (flash) {
            if (persistsForOneLocationChange) {
                setPersistsForOneLocationChange(false)
            } else {
                setFlash(null)
            }
        }
    }, [location])

    return (
        <FlashBagContext.Provider value={{addFlash, flash}}>
            {children}
        </FlashBagContext.Provider>
    )
}