import React from "react";
import {LongTextFieldPopover} from "./LongTextFieldPopover";

export const RecognitionConditionsPopover = ({course}) => {
    return (
        <LongTextFieldPopover
            header={'Conditions of recognition'}
            course={course}
            fieldKey={'recognitionConditions'}
            labelIfUndefined={'No information added by the student'}
        />
    )
}