import React from "react"
import logo from './SHARE ASEAN logo.png';
import {LoadingSpinner} from "./LoadingSpinner";

export const Splashscreen = () => {
    return (
        <div style={{
            margin: 0,
            position: 'absolute',
            textAlign: 'center',
            top: '50%',
            msTransform: 'translateY(-50%)',
            transform: 'translateY(-50%)',
            left: 0, right: 0, bottom: 0}
        }>
            <h1 className={'mb-3'}>DAECTS platform</h1>
            <br/><img className={'mb-3'} alt={'asean share logo'} src={logo} />
            <br/><div className={'mb-3'}>Loading...</div>
            <br/><LoadingSpinner/>
        </div>
    )
}