import React, {useState, useEffect} from 'react'
import {Offcanvas} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Form} from "../form/base/Form";
import {useCookies} from "react-cookie";

export const PoliciesAcceptation = () => {
    const [show, setShow] = useState(false);
    const [cookies, setCookies] = useCookies();
    const policiesAcceptationCookieName = 'consent'

    const acceptationCheckboxes = {
        'cookiesPolicy': false,
        'termsAndConditions': false,
        'privacyPolicy': false,
    }

    useEffect(() => {
        let consentCookie = cookies[policiesAcceptationCookieName]

        console.log('cookie', consentCookie)
        if(
            consentCookie === null
            || consentCookie === undefined
            || consentCookie === false
        ) {
            setShow(true)
        }
    }, [])

    const handleClose = () => setShow(false);

    const handleSubmit = () => {
        setCookies(
            policiesAcceptationCookieName,
            true
        )
        handleClose()
    }

    return (
        <>
            <Offcanvas placement="bottom" show={show} backdrop="static">
                <Offcanvas.Header closeButton={false}>
                    <Offcanvas.Title><span className="text-warning">Acceptation of the DAECTS platform's policies</span></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    In order to use this platform, you must accept its different policies by checking the following checkboxes:
                    <div className="text-muted"><small>If you do not accept these conditions, simply leave the website and your preferences will be respected.</small></div>
                    <Form
                        data={acceptationCheckboxes}
                        onSubmit={handleSubmit}
                        showBottomRequiredHelp={false}
                        submitButtonLabel="Accept and continue"
                        fields={[
                        {
                            label: <>
                                I accept the use of <Link to={'/cookies_policy'}>cookies that are required for the platform's basic functioning</Link>
                            </>,
                            colWrapperMd: 12,
                            controlId: 'cookiesPolicy',
                            type: 'checkbox',
                            required: true,
                        },
                        {
                            label: <>
                                I accept the DAECTS platform's <Link to={'/terms_and_conditions'}>terms and conditions</Link>
                            </>,
                            colWrapperMd: 12,
                            controlId: 'termsAndConditions',
                            type: 'checkbox',
                            required: true,
                        },
                        {
                            label: <>
                                I accept the DAECTS platform's <Link to={'/privacy_policy'}>privacy policy</Link>
                            </>,
                            colWrapperMd: 12,
                            controlId: 'privacyPolicy',
                            type: 'checkbox',
                            required: true,
                        },
                    ]}>
                    </Form>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
}