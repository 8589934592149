import React, {useContext, useEffect} from 'react'
import {Link, useParams} from "react-router-dom";
import {Mobility} from "../mobility/Mobility";
import {Alert, Button, ButtonGroup} from "react-bootstrap";
import {Pencil, Trash} from "react-bootstrap-icons";
import {TimelineIndicator} from "../mobility/TimelineIndicator";
import {Page} from "./Page";
import {Button as LearningAgreementButton} from "../learningAgreement/Button";
import {Button as TranscriptOfRecordsButton} from "../transcriptOfRecords/Button";
import {Button as CreditsRecognitionButton} from "../creditsRecognition/Button";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";
import {UserContext} from "../../utils/context/UserProvider";

export const MobilityPage = () => {
    let { mobilityId } = useParams()
    const {refreshCurrentUserStudentMobilities} = useContext(UserContext)

    const {
        errorNotFound: errorMobilityNotFound,
        isLoaded: isMobilityLoaded,
        data: mobility,
        queryApi: mobilityQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId,
            method: 'get',
        }
    )

    const {
        queryApi: mobilityDeleteQueryApi
    } = useApi(
        {
            url: '/api/mobilities/' + mobility?.id,
            method: 'delete',
            thenCallback: () => {
                refreshCurrentUserStudentMobilities()
            }
        }
    )

    const deleteMobility = () => {
        mobilityDeleteQueryApi()
    }

    useEffect(
        () => {
            mobilityQueryApi()
        }, [mobilityId]
    )

    return (
        <Page
            breadcrumbs={[
                {label: 'Mobility', active: true},
            ]}
            title={'Mobility details'}
        >
            {
                errorMobilityNotFound ?
                    <Alert variant={'danger'}>
                        No mobility found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isMobilityLoaded?
                        <LoadingSpinner/>
                        :
                        <>
                            <p><TimelineIndicator mobility={mobility}/> <small className={'text-muted'}>[ ID: {mobilityId} ]</small></p>

                            <ButtonGroup className={'mb-3'}>
                                {
                                    // if mobility can be edited
                                    mobility.learningAgreement === undefined
                                    || mobility.learningAgreement.status === 'draft'
                                    || mobility.learningAgreement.status === 'rejected_by_sending'
                                    || mobility.learningAgreement.status === 'rejected_by_receiving'
                                        ?
                                        <Button variant={'outline-primary'} as={Link} to={'/mobility/'+mobility.id+'/edit'}>
                                            <Pencil /> Edit mobility
                                        </Button>
                                        :
                                        <Button variant={'outline-primary'} disabled>
                                            <Pencil /> Edit mobility
                                        </Button>
                                }

                                {
                                    // if mobility can be deleted
                                    mobility.learningAgreement === undefined
                                    || mobility.learningAgreement === null
                                    || mobility.learningAgreement.status === 'draft' ?
                                        <Button variant={'outline-danger'} onClick={deleteMobility}>
                                            <Trash /> Delete mobility
                                        </Button>
                                        :
                                        <Button variant={'outline-danger'} disabled>
                                            <Trash /> Delete mobility
                                        </Button>
                                }
                            </ButtonGroup>

                            <div>
                                Documents:
                                <ButtonGroup className={'ms-3'}>
                                    <LearningAgreementButton mobility={mobility}/>
                                    <TranscriptOfRecordsButton mobility={mobility}/>
                                    <CreditsRecognitionButton mobility={mobility}/>
                                </ButtonGroup>

                                <Mobility mobility={mobility} />
                            </div>
                        </>
            }
        </Page>
    )
}