import React from 'react'
import {Link} from "react-router-dom";
import {Breadcrumb as BootstrapBreadcrumb} from 'react-bootstrap'

export const Breadcrumb = ({breadcrumbs}) => {
    return <BootstrapBreadcrumb>
        {
            ([{url: '/', label: 'Home'}].concat(breadcrumbs)).map(
                (breadcrumb, index) =>
                    breadcrumb.url === undefined ?
                        <BootstrapBreadcrumb.Item
                            key={index}
                            active={breadcrumb.active !== undefined && breadcrumb.active === true}
                        >
                            {breadcrumb.label}
                        </BootstrapBreadcrumb.Item>
                        :<BootstrapBreadcrumb.Item
                            key={index}
                            active={breadcrumb.active !== undefined && breadcrumb.active === true}
                            linkProps={{ to: breadcrumb.url }}
                            linkAs={Link}
                        >
                            {breadcrumb.label}
                        </BootstrapBreadcrumb.Item>
            )
        }
    </BootstrapBreadcrumb>
}