import React from 'react'
import {useFieldArray, useFormContext} from "react-hook-form";

import {Button, Col, Row} from "react-bootstrap";
import {PlusLg, Trash} from "react-bootstrap-icons";
import {FormField} from "./Form";

export const FieldArray = ({type, prototype, label, controlId, allowAdd = true, allowDelete = true}) => {
    const { control } = useFormContext();

    const {fields, append, remove} = useFieldArray({
        control,
        name: controlId
    })

    return <>
        {
            label && <h5>{label}</h5>
        }
        <div className={'field-array'}>
            <ul className={'field-array-list'}>
                {
                    fields.map(
                        (field, index) =>
                            <li key={field.id + index}>
                                <Row  className={'mb-3'}>
                                    {
                                        allowDelete ?
                                            <>
                                                <Col md={10} className={'field-array-item-fields'}>
                                                    <FormField
                                                        parentId={controlId+'['+index+']'}
                                                        field={type}
                                                    />
                                                </Col>
                                                <Col md={2}>
                                                    <Button variant="outline-danger" className={'field-array-remove'} onClick={() => remove(index)}><Trash/></Button>
                                                </Col>
                                            </>
                                            :<Col md={12} className={'field-array-item-fields'}>
                                                <FormField
                                                    parentId={controlId+'['+index+']'}
                                                    field={type}
                                                />
                                            </Col>

                                    }
                                </Row>
                            </li>
                    )
                }
            </ul>

            {
                allowAdd &&
                <Button variant="outline-primary" onClick={() => append(prototype)}><PlusLg/> Add new</Button>
            }
        </div>
    </>
}