import React, {createContext, useEffect, useState} from 'react'
import {useAuth} from "../hooks/useAuth";
import jwt from "jwt-decode";
import {useCookies} from "react-cookie";
import {useApi} from "../hooks/useApi";
import {Splashscreen} from "../../components/common/Splashscreen";

const UserContext = createContext([])

function UserProvider({ children }) {
    const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0)
    const [mustCompleteProfile, setMustCompleteProfile] = useState(true)

    const {isAuthenticated, tokenEmail} = useAuth()
    const [cookies] = useCookies(['token'])

    // Student
    const {
        isLoaded: isStudentLoaded,
        data: currentUserStudent,
        queryApi: studentQueryApi,
        resetData: resetStudent
    } = useApi(
        {
            url: '/api/users/'+tokenEmail+'/student',
            method: 'get',
        }
    )

    // Staff
    const {
        isLoaded: isStaffLoaded,
        data: currentUserStaff,
        queryApi: staffQueryApi,
        resetData: resetStaff
    } = useApi(
        {
            url: '/api/users/'+tokenEmail+'/staff',
            method: 'get',
        }
    )

    // Notifications
    const {
        isLoaded: areNotificationsLoaded,
        data: notifications,
        queryApi: notificationsQueryApi,
        resetData: resetNotifications
    } = useApi(
        {
            url: '/api/users/'+ tokenEmail +'/notifications',
            method: 'get',
            isCollection: true,
            thenCallback: (notificationData) => {
                setUnreadNotificationsCount(notificationData['unreadNotificationsCount'])
            }
        }
    )

    // Staff heis
    const {
        isLoaded: areStaffHeisLoaded,
        data: currentUserStaffHeis,
        queryApi: staffHeisQueryApi,
        resetData: resetStaffHeis
    } = useApi(
        {
            url: '/api/staff/'+currentUserStaff?.id+'/heis',
            method: 'get',
            isCollection: true
        }
    )

    // Student home hei
    const {
        isLoaded: isStudentHomeHeiLoaded,
        data: currentUserStudentHomeHei,
        queryApi: studentHomeHeiQueryApi,
        resetData: resetStudentHomeHei
    } = useApi(
        {
            url: currentUserStudent?.homeHei,
            method: 'get'
        }
    )

    // Student mobilities
    const {
        isLoaded: areStudentHomeMobilitiesLoaded,
        data: currentUserStudentMobilities,
        queryApi: studentMobilitiesQueryApi,
        resetData: resetStudentMobilities
    } = useApi(
        {
            url: '/api/students/'+currentUserStudent?.id+'/mobilities',
            method: 'get',
            isCollection: true
        }
    )

    // Load/reset student and/or staff according to roles, and their notifications
    useEffect(() => {
        resetUserData()

        if (isAuthenticated()) {
            if (cookies['token'] !== null && tokenEmail !== null) {
                refreshNotifications()

                let decodedToken = jwt(cookies['token'])
                if (decodedToken['roles'].includes('ROLE_STUDENT')) {
                    studentQueryApi()
                }

                if (decodedToken['roles'].includes('ROLE_HEI_STAFF')) {
                    staffQueryApi()
                }
            }
        }
    }, [tokenEmail])

    // Load staff related resources
    useEffect(() => {
        resetStaffHeis()

        if (currentUserStaff !== null){
            // TODO add all the fields that should be completed, for safety purposes
            if (
                currentUserStaff.contactDetails !== undefined
                && currentUserStaff.contactDetails !== null
            ) {
                setMustCompleteProfile(false)
            }
            staffHeisQueryApi()
        }
    }, [currentUserStaff])

    // Load student related resources
    useEffect(() => {
        resetStudentHomeHei()

        if (currentUserStudent !== null) {
            // Testing if the profile has been completed
            // TODO add all the fields that should be completed, for safety purposes
            if (
                currentUserStudent.birthDate !== undefined
                && currentUserStudent.birthDate !== null
            ) {
                setMustCompleteProfile(false)
            }
            studentHomeHeiQueryApi()
        }

        refreshCurrentUserStudentMobilities()
    }, [currentUserStudent])

    const resetUserData = () => {
        setUnreadNotificationsCount(0)
        setMustCompleteProfile(true)
        resetNotifications()
        resetStaff()
        resetStaffHeis()
        resetStudent()
        resetStudentHomeHei()
        resetStudentMobilities()
    }

    const refreshCurrentUserStudent = () => {
        resetStudent()
        if (currentUserStudent !== null){
            studentQueryApi()
        }
    }

    const refreshCurrentUserStaff = () => {
        resetStaff()
        if (currentUserStaff !== null){
            staffQueryApi()
        }
    }

    const refreshCurrentUserStudentMobilities = () => {
        resetStudentMobilities()
        if (currentUserStudent !== null){
            studentMobilitiesQueryApi()
        }
    }

    const refreshNotifications = () => {
        notificationsQueryApi()
    }

    const isCurrentUserStaffAtHei = (schacCode) => {
        //TODO use claims when available
        return currentUserStaffHeis !== null && currentUserStaffHeis.some(hei => hei.schacCode === schacCode)
    }

    return (
        <UserContext.Provider value={
            {
                mustCompleteProfile,
                currentUserStaff,
                currentUserStaffHeis,
                currentUserStudent,
                currentUserStudentMobilities,
                refreshCurrentUserStudent,
                refreshCurrentUserStudentMobilities,
                currentUserStudentHomeHei,
                notifications,
                unreadNotificationsCount,
                refreshNotifications,
                refreshCurrentUserStaff,
                isCurrentUserStaffAtHei
            }}>
            {
                (
                    !isAuthenticated()
                    ||
                    (
                        areNotificationsLoaded &&
                        (
                            (
                                isStudentLoaded
                                && isStudentHomeHeiLoaded
                                && areStudentHomeMobilitiesLoaded
                            )
                            ||
                            (
                                isStaffLoaded
                                && areStaffHeisLoaded
                            )
                        )
                    )
                )
                ?
                    children
                    :
                    <Splashscreen/>
            }
        </UserContext.Provider>
    )
}

export { UserProvider, UserContext }