import React from 'react'
import { MobilitiesPage } from '../components/pages/MobilitiesPage'
import {SettingsPage} from "../components/pages/SettingsPage";
import {HeiPage} from "../components/pages/HeiPage";

const routes =  [
	{path: '/university/:schacCode', element: <HeiPage />},
	{path: '/university/:schacCode/incoming_mobilities', element: <MobilitiesPage mobilityMode={"incoming"} />},
	{path: '/university/:schacCode/outgoing_mobilities', element: <MobilitiesPage mobilityMode={"outgoing"} />},
	{path: '/university/:schacCode/settings', element: <SettingsPage />},
]

export default routes