import React from "react"
import {Link} from "react-router-dom";
import {LearningAgreement} from "../icons/LearningAgreement";
import {Button as BootstrapButton} from "react-bootstrap";

export const Button = ({mobility}) => {
    if (mobility !== undefined) {
        return (
            mobility.learningAgreement === undefined ?
                <BootstrapButton as={Link} to={'/mobility/' + mobility.id + '/learning_agreement'} variant={'primary'}>
                    <LearningAgreement/> Create learning agreement
                </BootstrapButton>
                :
                <BootstrapButton as={Link} to={'/learning_agreement/' + mobility.id} variant={'outline-secondary'}>
                    <LearningAgreement/> Learning agreement
                </BootstrapButton>
        )
    }
}