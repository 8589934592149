import React from 'react'
import {CourseGradesTable as GenericCourseGradeTable} from "../course/CourseGradesTable";

export const CourseGradesTable = ({ courseGrades }) => {
    const legend = [
        {variant: 'success', label: <span>Course is <strong>in the learning agreement</strong> and <strong>passed</strong></span>},
        {variant: 'danger', label: <span>Course is <strong>in the learning agreement</strong> and was <strong>failed</strong></span>},
        {variant: 'light', label: <span className={'text-success'}>Course does <strong>NOT come from the learning agreement</strong> and <strong>passed</strong></span>},
        {variant: 'light', label: <span className={'text-warning'}>Course does <strong>NOT come from the learning agreement</strong> but was <strong>failed</strong></span>},
    ]

    const creditsKey = 'credits'

    return (
        <GenericCourseGradeTable
            courseGrades={courseGrades}
            legend={legend}
            creditsKey={creditsKey}
        />
    )
}
