import {SelectEqfLevel} from "./SelectEqfLevel";
import {HeiContactDetailsLoadingDefaultButton} from "./HeiContactDetailsLoadingDefaultButton";
import {SelectReceivingHei} from "./SelectReceivingHei";
import {SelectSendingHei} from "./SelectSendingHei";
import {SelectIscedFCode} from "./SelectIscedFCode";

export const Mobility = [
    {
        label: 'Start date',
        controlId: 'startDate',
        type: 'date',
    },
    {
        label: 'End date',
        controlId: 'endDate',
        type: 'date',
    },
    {
        label: 'ISCED F code',
        controlId: 'iscedFCode',
        type: SelectIscedFCode,
    },
    {
        label: 'ISCED F code clarification',
        controlId: 'iscedClarification',
        type: 'textarea',
        required: false,
    },
    {
        label: 'Sending university',
        placeholder: 'Select sending university',
        controlId: 'sendingHei',
        type: SelectSendingHei,
    },
    {
        label: 'Receiving university',
        placeholder: 'Select receiving university',
        controlId: 'receivingHei',
        type: SelectReceivingHei,
    },
    {
        label: 'Academic year at the receiving university (format: YYYY/YYYY)',
        controlId: 'receivingHeiAcademicYear',
    },
    {
        label: 'EQF level at the time of the mobility',
        controlId: 'eqfLevelAtDeparture',
        type: SelectEqfLevel,
    },
    {
        colWrapperMd: 12,
        controlId: 'sendingHeiContactPerson',
        heiControlId: 'sendingHei',
        type: HeiContactDetailsLoadingDefaultButton,
        heiRole: 'sending'
    },
    {
        colWrapperMd: 12,
        controlId: 'receivingHeiContactPerson',
        heiControlId: 'receivingHei',
        type: HeiContactDetailsLoadingDefaultButton,
        heiRole: 'receiving'
    }
]