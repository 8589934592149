import React, {useEffect, useState} from 'react'
import {MultiStepBar} from "../common/MultiStepBar";

export const Flow = ({learningAgreement}) => {
    const [steps, setSteps] = useState([])
    const rejectedBySendingStep = {
        label: <>Rejected by<br />sending university</>,
        status: 'rejected_by_sending',
        colorOnActive: 'text-danger',
        overlay: {
            header: 'The sending university rejected the learning agreement.',
            body: <>
                The student must:
                <ul>
                    <li><strong>read</strong> the rejection comments,</li>
                    <li><strong>modify</strong> their learning agreement accordingly,</li>
                    <li>and <strong>submit once again</strong> for review.</li>
                </ul>
            </>,
        },
    }

    const rejectedByReceivingStep = {
        label: <>Rejected by<br />receiving university</>,
        status: 'rejected_by_receiving',
        colorOnActive: 'text-danger',
        overlay: {
            header: 'The receiving university rejected the learning agreement.',
            body: <>
                The student must:
                <ul>
                    <li><strong>read</strong> the rejection comments,</li>
                    <li><strong>modify</strong> their learning agreement accordingly,</li>
                    <li>and <strong>submit once again</strong> for review.</li>
                </ul>
            </>,
        },
    }

    const draftStep = {
        label: 'Draft',
        status: 'draft',
        colorOnActive: 'text-black',
        overlay: {
            header: 'The learning agreement has not been submitted by the student yet.',
            body: <>
                The student must:
                <ul>
                    <li><strong>complete</strong> their learning agreement,</li>
                    <li>and <strong>submit</strong> it for review.</li>
                </ul>
            </>,
        },
    }

    useEffect(() => {
        let firstStep
        if (learningAgreement.rejectionReceivingComment !== undefined) {
            firstStep = rejectedByReceivingStep
        } else if (learningAgreement.rejectionSendingComment !== undefined) {
            firstStep = rejectedBySendingStep
        } else {
            firstStep = draftStep
        }

        let updatedSteps = [
            firstStep,
            {
                label: 'Submitted',
                status: 'submitted_by_student',
                colorOnActive: 'text-primary',
                overlay: {
                    header: 'The learning agreement has been submitted by the student.',
                    body: <>
                        Their home university must now <strong>accept</strong> or <strong>reject</strong> the submission.
                    </>,
                },
            },
            {
                label: <>Accepted by<br />sending university</>,
                status: 'accepted_by_sending',
                colorOnActive: 'text-primary',
                overlay: {
                    header: 'The sending university accepted the learning agreement.',
                    body: <>
                        The receiving university must now <strong>accept</strong> or <strong>reject</strong> the submission.
                    </>,
                },
            },
            {
                label: <>Accepted by<br />receiving university</>,
                colorOnActive: 'text-success',
                status: 'accepted_by_receiving',
                overlay: {
                    header: 'Both universities accepted the learning agreement.',
                    body: <>
                        An official PDF version of the learning agreement is now available for all parties.
                    </>,
                },
            }
        ]

        let stepIsBeforeOrCurrent = true
        updatedSteps.forEach(
            step => {
                step.overlay.id = step.status + '-overlay'

                if (stepIsBeforeOrCurrent) {
                    step.completed = true
                }

                if (step.status === learningAgreement.status) {
                    stepIsBeforeOrCurrent = false
                    step.active = true
                }
            }
        )

        setSteps(updatedSteps)

    }, [learningAgreement])

    return (
        steps.length > 0 &&
        <MultiStepBar steps={steps} />
    )
}