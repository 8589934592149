import {createContext, useContext, useEffect, useState} from "react";
import jwt from "jwt-decode";
import {useNavigate} from "react-router-dom";
import {FlashBagContext} from "./FlashBagProvider";
import {useCookies} from "react-cookie";


export const AuthContext = createContext([])

export const AuthProvider = ({ children }) => {
    const [cookies, setCookie, removeCookie] = useCookies(['token'])
    const [tokenEmail, setTokenEmail] = useState(null);
    const [authenticated, setAuthenticated] = useState(cookies['token'] !== null)

    const {addFlash} = useContext(FlashBagContext)
    const navigate = useNavigate()

    // On load check if token is still here
    useEffect(() => {
        if (cookies['token'] !== null) {
            let decodedToken
            try {
                decodedToken = jwt(cookies['token'])
                setTokenEmail(decodedToken.username)

                setAuthenticated(true)
            } catch (e) {
                logout()
            }
        }
    }, [])

    const login = (token) => {
        if ( token !== null) {
            let decodedToken = jwt(token)
            setCookie('token', token, { path: '/', expires: new Date(decodedToken.exp * 1000) })
            setTokenEmail(decodedToken.username)
            setAuthenticated(true)
        } else {
            if (cookies['token'] !== null) {
                removeCookie('token')
            }
            setTokenEmail(null)
            setAuthenticated(false)
        }
    };

    const isAuthenticated = () => {
        if (cookies['token'] === null) {
            addFlash({
                severity: 'warning',
                message: 'Your session can last 1 hour maximum for security reasons. Please log in again.',
                persistsForOneLocationChange: true
            })
            logout()
        }

        return authenticated
    }

    const logout = () => {
        setAuthenticated(false)
        setTokenEmail(null)
        if (cookies['token'] !== null) {
            removeCookie('token', {path:'/', domain: '.asean.uni-foundation.eu'})
        }

        navigate('/login')
    };

    return (
        <AuthContext.Provider value={{isAuthenticated, login, logout, tokenEmail}}>
            {children}
        </AuthContext.Provider>
    );
};