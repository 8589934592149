import React, {useMemo} from 'react'
import {Select} from "../base/Select";
import countryList from "react-select-country-list";

export const SelectCountry = (props) => {
    const options = useMemo(() => countryList().getData(), [])

    return <Select
        {...props}
        label={props.label || 'Country'}
        placeholder={props.placeholder || 'Select country'}
        options={options}
    />
}