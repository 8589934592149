import React  from 'react'
import PropTypes from 'prop-types'
import { Container, Navbar, Nav, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'

import {Notifications} from "./Notifications"
import {BoxArrowRight} from "react-bootstrap-icons"
import {useAuth} from "../../utils/hooks/useAuth";

export const Header = () => {
  const { isAuthenticated, logout, tokenEmail } = useAuth();

  return (
    <Navbar bg="light" expand="lg">
      <Container>
        <Link className="navbar-brand" to="/">DAECTS</Link>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">

          </Nav>
        </Navbar.Collapse>
        <Navbar.Collapse className="justify-content-end">
          {
            isAuthenticated()
            ? (
                <>
                  <Notifications />
                  <Navbar.Text>
                    {tokenEmail}
                  </Navbar.Text>
                  <Button variant="link" onClick={logout}><BoxArrowRight /> Logout</Button>
                </>
            )
            : (
                    <a className="btn btn-link" href={process.env.REACT_APP_API_URL + "/oauth/asean_idp"}>Login</a>
            )
          }
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}

Header.propTypes = {
  user: PropTypes.shape({}),
  notificationOpen: PropTypes.bool,
}

Header.defaultProps = {
  user: null,
  notificationOpen: false
}
