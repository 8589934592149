import React, {useContext} from 'react'
import {SelectHei} from "./SelectHei";
import {UserContext} from "../../../utils/context/UserProvider";

export const SelectReceivingHei = (props) => {
    const {currentUserStudent} = useContext(UserContext)
    if (currentUserStudent !== null) {
        return <SelectHei
            {...props}
            label={props.label || 'Receiving university'}
            placeholder={props.placeholder || 'Select receiving university'}
            irisToRemove={[currentUserStudent.homeHei]}
        />
    }
}