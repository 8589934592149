import React, {useContext, useEffect} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Alert} from 'react-bootstrap'
import { LearningAgreementStudent } from "../learningAgreement/LearningAgreementStudent";
import { LearningAgreementStaff } from "../learningAgreement/LearningAgreementStaff";
import {Status} from "../learningAgreement/Status";
import {Page} from "./Page";
import {UserContext} from "../../utils/context/UserProvider";
import {Flow} from "../learningAgreement/Flow";
import {LearningAgreement as LearningAgreementIcon} from "../icons/LearningAgreement";
import {PdfButton} from "../learningAgreement/PdfButton";
import {LearningAgreement} from "../learningAgreement/LearningAgreement";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

export const LearningAgreementPage = () => {
    const {currentUserStaff, currentUserStudent} = useContext(UserContext)

    let { mobilityId } = useParams()

    const {
        errorNotFound: errorLearningAgreementNotFound,
        isLoaded: isLearningAgreementLoaded,
        data: learningAgreement,
        queryApi: learningAgreementQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/learning_agreement',
            method: 'get',
        }
    )

    useEffect(() => {
        learningAgreementQueryApi()
    }, [mobilityId])

    const handleAcceptation = () => {
        learningAgreementQueryApi()
    }

    const handleRejection = () => {
        learningAgreementQueryApi()
    }

    return (
        <Page
            breadcrumbs={[
                {url: '/mobility/' + mobilityId, label: 'Mobility'},
                {label: 'Learning agreement', active: true},
            ]}
            title={<><LearningAgreementIcon/> Learning Agreement</>}
        >
            {
                errorLearningAgreementNotFound ?
                    <Alert variant={'danger'}>
                        No learning agreement found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isLearningAgreementLoaded?
                        <LoadingSpinner/>
                        :
                        Object.keys(learningAgreement).length !== 0 &&
                        <>
                            <p>
                                <Status learningAgreement={learningAgreement} /> <small className={'text-muted'}>[ ID: {mobilityId} ]</small>
                            </p>

                            <div className={'mb-3'}>
                                <PdfButton learningAgreement={learningAgreement}/>
                            </div>

                            <h2>Active version <small className={'text-muted'}>(with potential changes since last official version)</small></h2>
                            <Flow learningAgreement={learningAgreement}/>

                            {
                                (currentUserStaff !== null) ?
                                    (
                                        <LearningAgreementStaff
                                            learningAgreement={learningAgreement}
                                            acceptCallback={handleAcceptation}
                                            rejectCallback={handleRejection}
                                        />
                                    )
                                    : (currentUserStudent !== null) && (
                                    <LearningAgreementStudent
                                        learningAgreement={learningAgreement}
                                    />
                                )
                            }

                            <LearningAgreement learningAgreement={learningAgreement}/>
                        </>
            }
        </Page>
    )

}
