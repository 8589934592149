import React, {createContext, useContext} from 'react'
import axios from "axios";
import {Cookies} from "react-cookie";
import {FlashBagContext} from "./FlashBagProvider";
import {useAuth} from "../hooks/useAuth";

export const ApiContext = createContext([])

export function ApiProvider({ children }) {
    const {addFlash} = useContext(FlashBagContext)
    const {logout} = useAuth()

    const api = axios.create({
        baseURL: process.env.REACT_APP_API_URL
    })

    api.interceptors.request.use(
        function (config) {
            const cookies = new Cookies();

            let tokenCookie = cookies.get('token')
            if (tokenCookie !== undefined) {
                config.headers.Authorization = 'Bearer ' + cookies.get('token');
            }

            return config;
        },
    )

    api.interceptors.response.use(
        function (response) {
            return response;
        },
        function (error) {
            let statusCode = error.response.status

            if (statusCode === 401) {
                addFlash({
                    message: 'Your session expired, please login again.',
                    severity: 'warning',
                    persistsForOneLocationChange: true,
                })
                logout()
            } else if (statusCode === 403) {
                addFlash({
                    message: 'You do not have access to this resource.',
                    severity: 'danger',
                })

                //TODO do nothing?
            } else if (statusCode === 404) {
                addFlash({
                    message: 'This resource does not exist.',
                    severity: 'warning',
                })

                //TODO redirect to 404 page?
            } else if (statusCode === 422) {
                addFlash({
                    message: 'The changes could not be saved because of an error.',
                    severity: 'danger',
                })

                //TODO how to take care of
            } else {
                addFlash({
                    message: 'An unexpected error happened, please try again.',
                    severity: 'danger',
                })
            }

            return Promise.reject(error);
        }
    )

    const apiFetch = (
        {
            url,
            method,
            body,
            queryParameters,
            thenCallback = function(){},
            errorCallback = function(){},
            finallyCallback = function(){},
        }
    ) => {
        let lowerCasedMethod = method.toLowerCase()

        let headers = {}
        if (lowerCasedMethod === 'patch') {
            headers = {
                'Content-Type': 'application/merge-patch+json'
            }

            // Fix an axios bug preventing content type change when data is empty
            if (body === undefined) {
                body = {}
            }
        }

        api.request({
            data: body,
            method,
            url,
            headers,
            params: queryParameters
        }).then(
            (response) => {
                thenCallback(response?.data)
            }
        ).catch(
            (error) => {
                errorCallback(error)
            }
        ).finally(
            (response) => {
                finallyCallback(response)
            }
        )
    }

    return (
        <ApiContext.Provider value={{ apiFetch }}>
            {children}
        </ApiContext.Provider>
    )
}