import React from "react";
import {Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {CheckLg} from "react-bootstrap-icons";

export const MultiStepBar = ({steps}) => {
    const classPrefix = 'multi-step'

    return (
        <div>
            <div className={classPrefix}>
                <Row className={classPrefix + '-list mb-3'}>
                    {
                        steps.map(
                            (step, index) =>
                                <Col key={index + 'step-labels'} className={classPrefix + '-item' + (step.completed? ' completed': '') + (step.active? ' active '+ step.colorOnActive:'')}>
                                    <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                                        <Popover id={step.overlay.id}>
                                            <Popover.Header>
                                                {step.overlay.header}
                                            </Popover.Header>
                                            <Popover.Body>
                                                {step.overlay.body}
                                            </Popover.Body>
                                        </Popover>
                                    }>
                                        <div>
                                            <hr />
                                            <div className={classPrefix + '-circle'}>
                                                {
                                                    step.completed?
                                                        <CheckLg/>
                                                        :<>&nbsp;</>
                                                }
                                            </div>
                                            <div className={classPrefix + '-label'}>{step.label}</div>
                                        </div>
                                    </OverlayTrigger>
                                </Col>
                        )
                    }
                </Row>
            </div>
        </div>
    )
}