import {CourseFieldArray} from "./CourseFieldArray";

export const SendingHeiCoursesSelection = [
    {
        colWrapperMd: 12,
        label: 'Link to the description of the provisions in case courses are not completed',
        controlId: 'provisionsIfIncompleteUrl',
        type: 'url',
    },
    {
        colWrapperMd: 12,
        label: 'Courses supposed to be followed at the sending university',
        controlId: 'sendingCourses',
        type: CourseFieldArray,
    },
]