import React, {useContext} from 'react'
import {StudentDashboard} from "../student/StudentDashboard";
import {StaffDashboard} from "../staff/StaffDashboard";
import {UserContext} from "../../utils/context/UserProvider";

export const Dashboard = () => {
    const {currentUserStaff, currentUserStudent} = useContext(UserContext)

  if (currentUserStaff !== null) {
    return (
      <StaffDashboard />
    )
  } else if (currentUserStudent !== null) {
      return (
        <StudentDashboard />
      )
  }
}
