import React from 'react'
import {Col, Row} from "react-bootstrap";

export const ContactDetails = ({contactDetails}) => {

    return (
        <>
            <Row>
                <Col>
                    <Row className="mb-0">
                        <Col md={4}>
                            <p className="fw-bold">Given name(s)</p>
                        </Col>
                        <Col>
                            <p className="ms-2">{contactDetails.givenNames}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    <Row className="mb-0">
                        <Col md={4}>
                            <p className="fw-bold">Family name(s)</p>
                        </Col>
                        <Col>
                            <p className="ms-2">{contactDetails.familyNames}</p>
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Row className="mb-0">
                        <Col md={4}>
                            <p className="fw-bold">Email</p>
                        </Col>
                        <Col>
                            <p className="ms-2">{contactDetails.email}</p>
                        </Col>
                    </Row>
                </Col>
                <Col>
                    {
                        <Row className="mb-0">
                            <Col md={4}>
                                <p className="fw-bold">Phone number</p>
                            </Col>
                            <Col>
                                <p className="ms-2">{contactDetails.phoneNumber || 'Not communicated'}</p>
                            </Col>
                        </Row>
                    }
                </Col>
            </Row>
        </>
    )
}