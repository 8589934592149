import React from "react"
import {Link} from "react-router-dom";
import {Button as BootstrapButton} from "react-bootstrap";
import {CreditsRecognition} from "../icons/CreditsRecognition";

export const Button = ({mobility}) => {
    if (mobility !== undefined) {
        return (
            mobility.transcriptOfRecords === undefined ?
                <BootstrapButton disabled variant={'outline-secondary'}>
                    <CreditsRecognition/> Credits recognition
                </BootstrapButton>
                :
                <BootstrapButton as={Link} to={'/credits_recognition/' + mobility.id} variant={'outline-secondary'}>
                    <CreditsRecognition/> Credits recognition
                </BootstrapButton>
        )
    }
}