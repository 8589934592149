import React, {useContext, useEffect, useState} from 'react'
import {useForm, FormProvider} from "react-hook-form";
import {Alert, Button, Col, Form as BootstrapForm, Modal, Row} from "react-bootstrap";
import {Field} from "./Field";
import {useNavigate} from "react-router-dom";
import {FlashBagContext} from "../../../utils/context/FlashBagProvider";
import {useApi} from "../../../utils/hooks/useApi";

export const Form = ({
                         fields= [],
                         data,
                         method,
                         submitUrl,
                         before,
                         after,
                         showTopRequiredHelp,
                         showBottomRequiredHelp,
                         submitButtonLabel,
                         submitButtonVariant,
                         resetButtonLabel,
                         isModal = false,
                         redirectOnSuccess,
                         successMessage,
                         requiresConfirmation = false,
                         confirmationModalHeader,
                         confirmationModalBody,
                         onSubmit,
                     }) => {
    const useFormProps = useForm({defaultValues: data});
    const {reset, handleSubmit, setError, formState: { isSubmitting } } = useFormProps
    const navigate = useNavigate()
    const { addFlash } = useContext(FlashBagContext)
    const [showConfirmModal, setShowConfirmModal] = useState(false);

    const [submittedData, setSubmittedData] = useState(null)

    const {
        queryApi: submitQueryApi
    } = useApi(
        {
            url: submitUrl,
            method: method,
            body: submittedData,
            thenCallback: (response) => {
                let severity = 'success'
                let message = successMessage || 'The changes are successfully saved.'

                if (redirectOnSuccess !== undefined) {
                    addFlash({severity: severity, message: message, persistsForOneLocationChange: true})
                    navigate(typeof redirectOnSuccess === 'string' ? redirectOnSuccess: redirectOnSuccess(response))
                } else {
                    addFlash({severity: severity, message: message})
                }
            },
            errorCallback: (response) => {
                if (response?.response?.status === 422) {
                    let violations = response.response.data.violations
                    violations.forEach(
                        // TODO handle global error message addition if no path
                        violation => {
                            setError(
                                violation.propertyPath,
                                { type: 'custom', message: violation.message }
                            )
                        }
                    )
                }
            },
            finallyCallback: () => {
                // Animate to top (if staying on the same page)
                setTimeout(() => {
                    window.scrollTo({top: 0, behavior: 'smooth'})
                }, 0)

                setSubmittedData(null)

                if (requiresConfirmation === true) {
                    handleCloseConfirmModal()
                }
            }
        }
    )
    const onSubmitDefault = (submitData) => {
        setSubmittedData(submitData)
    }

    const handleCloseConfirmModal = () => {
        setShowConfirmModal(false)
    }

    useEffect(() =>{
        reset(data)
    }, [data])

    useEffect(() =>{
        if (
            submittedData !== null
        ) {
            if (requiresConfirmation === true) {
                setShowConfirmModal(true)
            } else {
                submitQueryApi()
            }
        }
    }, [submittedData])

    return (
        <FormProvider {...useFormProps}>
            <BootstrapForm onSubmit={handleSubmit(onSubmit? onSubmit: onSubmitDefault)}
                           onReset={reset} >
                {
                    isModal ?
                        <Modal.Body>
                            <FormBody fields={fields} before={before} after={after} showTopRequiredHelp={showTopRequiredHelp} showBottomRequiredHelp={showBottomRequiredHelp}/>
                        </Modal.Body>
                        :<FormBody fields={fields} before={before} after={after} showTopRequiredHelp={showTopRequiredHelp} showBottomRequiredHelp={showBottomRequiredHelp}/>
                }

                {
                    isModal ?
                        <Modal.Footer>
                            <FormButtons resetCallback={() => {reset(data)}} disabled={ isSubmitting }
                                         submitButtonLabel={submitButtonLabel} submitButtonVariant={submitButtonVariant} resetButtonLabel={resetButtonLabel}/>
                        </Modal.Footer>
                        : <FormButtons resetCallback={() => {reset(data)}} disabled={ isSubmitting }
                                       submitButtonLabel={submitButtonLabel} submitButtonVariant={submitButtonVariant} resetButtonLabel={resetButtonLabel}/>
                }
            </BootstrapForm>
            {
                requiresConfirmation &&
                <Modal show={showConfirmModal} onHide={handleCloseConfirmModal}>
                    <Modal.Header closeButton>
                        <Modal.Title>{confirmationModalHeader || 'Confirmation of submission'}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {confirmationModalBody || <Alert variant={'warning'}><strong>Would you like to proceed?</strong></Alert>}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseConfirmModal}>
                            Cancel
                        </Button>
                        <Button variant="primary" onClick={() => submitQueryApi()}>
                            Confirm
                        </Button>
                    </Modal.Footer>
                </Modal>
            }
        </FormProvider>
    );
}


export const FormButtons = ({
                                submitButtonLabel = 'Submit',
                                resetButtonLabel = 'Reset changes',
                                submitButtonVariant = 'primary',
                                disabled = false,
                                resetCallback,
                            }) => {


    return (
        <>
            <Button variant={submitButtonVariant} type="submit"
                    disabled={disabled }>
                {submitButtonLabel}
            </Button> <Button
            variant={'outline-warning'}
            onClick={resetCallback}
            disabled={disabled }
        >
            {resetButtonLabel}
        </Button>
        </>
    )
}

export const FormBody = ({fields, control, showTopRequiredHelp = true, showBottomRequiredHelp = true, errors, before, after}) => {
    return (
        <>
            {
                before !== undefined && before
            }

            {
                showTopRequiredHelp &&
                <div>
                    <small className={'text-muted'}>Fields marked with a <span className={'label-for-required'}/> are required</small>
                </div>
            }

            <FormField field={fields} errors={errors} control={control} />

            {
                showBottomRequiredHelp &&
                <div>
                    <small className={'text-muted'}>Fields marked with a <span className={'label-for-required'}/> are required</small>
                </div>
            }

            {
                after !== undefined && after
            }
        </>
    )
}

export const FormField = ({field, parentId}) => {
    const controlIdPrefix = parentId ? parentId+'.': ''

    return (
        <>
            {
                typeof(field) === 'function' ?
                    <FormField
                        field={() => field()}
                        parentId={parentId}
                    />
                    : <Col md={field.colWrapperMd || (Array.isArray(field) ? 12: 6)}>
                        {
                            field.before !== undefined &&
                            field.before
                        }

                        {
                            Array.isArray(field) ?
                                <>
                                    <Row>
                                        {
                                            field.map(
                                                (fieldChild, index) =>
                                                    <FormField
                                                        key={controlIdPrefix + fieldChild.controlId + index}
                                                        field={fieldChild}
                                                        parentId={parentId}
                                                    />
                                            )
                                        }
                                    </Row>
                                </>
                                : typeof(field.type) === 'function' ?
                                    field.type({...field, controlId: controlIdPrefix + field.controlId})
                                    : Array.isArray(field.type) ?
                                        <>
                                            {
                                                field.label && <h5>{field.label}</h5>
                                            }
                                            <FormField
                                                field={field.type}
                                                parentId={controlIdPrefix + field.controlId}
                                            />
                                        </>
                                        : <Field
                                            {...field}
                                            controlId={controlIdPrefix + field.controlId}
                                        />
                        }

                        {
                            field.after !== undefined &&
                            field.after
                        }
                    </Col>
            }
        </>
    )
}