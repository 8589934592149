import React from 'react'
import {FieldArray} from "../base/FieldArray";
import {CourseGrade} from "./CourseGrade";

export const CourseGradeFieldArray = (props) => {
    let label = props.label || 'Course grades'

    let prototype = {
        course: {
            'losCode': '',
            'title': '',
            'credits': 0,
            'recognitionConditions': '',
            'shortDescription': '',
        },
        grade : '',
        passed: false,
    }

    return <FieldArray
        {...props}
        type={CourseGrade}
        prototype={prototype}
        label={ label }
    />
}