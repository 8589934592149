import React from 'react'
import {Form} from "./base/Form";
import {CourseCreditsEquivalenceFieldArray} from "./type/CourseCreditsEquivalenceFieldArray";
import {Alert} from "react-bootstrap";

export const CreditsEquivalenceForm = ({learningAgreement}) => {
    return (
        <Form
            fields={[
                {
                    controlId: 'receivingCourses',
                    colWrapperMd: 12,
                    type: CourseCreditsEquivalenceFieldArray,
                }
            ]}
            data={learningAgreement}
            method={'patch'}
            showTopRequiredHelp={false}
            submitButtonLabel={'Assign credits equivalence and accept LA'}
            submitUrl={'/api/learning_agreements/' + learningAgreement.id + '/sending/accept'}
            successMessage="The learning agreement was accepted and credits equivalence saved. Notifications were sent to the student and the receiving university."
            redirectOnSuccess={
                learningAgreementResponse => {
                    return '/learning_agreement/' + learningAgreementResponse.mobility.id
                }
            }
            requiresConfirmation={true}
            confirmationModalHeader={'Accepting the learning agreement'}
            confirmationModalBody={
                <Alert variant={'warning'}>
                    <p>
                        You are about to accept this learning agreement.
                    </p>
                    <p>
                        This action is irreversible and will trigger a notification to the receiving university that will then need to accept or reject it.
                    </p>

                    <p>
                        <strong>Would you like to proceed?</strong>
                    </p>
                </Alert>
            }
        />
    );
}