import React from 'react'
import {Blockquote} from "../common/Blockquote";
import {Alert} from "react-bootstrap";
import {Link} from "react-router-dom";

export const RejectionAlert = (props) => {
    const learningAgreement = props.learningAgreement
    const mode = props.mode

    if (
        learningAgreement.status === 'rejected_by_sending'
        || learningAgreement.status === 'rejected_by_receiving'
    ) {
        return (
            <Alert variant={'danger'}>
                <em>
                    {
                        mode === 'student'?
                            (
                                <>
                                    Your {(learningAgreement.status === 'rejected_by_sending')? ('home'): ('receiving')} university rejected your learning agreement submission
                                </>
                            )
                            : (mode === 'sendingHei')?
                                (
                                    <>
                                        {(learningAgreement.status === 'rejected_by_sending')? ('You'): ('The receiving university')} rejected the learning agreement submission
                                    </>
                                )
                                :(mode === 'receivingHei')?
                                    (
                                        <>
                                            {(learningAgreement.status === 'rejected_by_sending')? ('The sending university'): ('You')} rejected the learning agreement submission
                                        </>
                                    )
                                    :('The learning agreement submission was rejected')
                    } for the following reasons:
                </em>
                <Blockquote
                    quote={(learningAgreement.status === 'rejected_by_sending')? (learningAgreement.rejectionSendingComment):(learningAgreement.rejectionReceivingComment)}
                />
                {
                    mode === 'student' &&
                    (
                        <strong>Please <Link to={'/mobility/' + learningAgreement.mobility.id + '/learning_agreement'}>modify your learning agreement</Link> according to their comments and submit it for review once more.</strong>
                    )
                }
            </Alert>
        )
    }
}