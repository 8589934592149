import React from 'react'
import {LearningAgreementOverviewPage} from "../components/pages/LearningAgreementOverviewPage";
import {MobilityPage} from "../components/pages/MobilityPage";
import {MobilityEditPage} from "../components/pages/MobilityEditPage";
import {MobilityCreatePage} from "../components/pages/MobilityCreatePage";
import {SendingHeiCoursesSelectionPage} from "../components/pages/SendingHeiCoursesSelectionPage";
import {ReceivingHeiCoursesSelectionPage} from "../components/pages/ReceivingHeiCoursesSelectionPage";
import {LearningAgreementCreditsEquivalencePage} from "../components/pages/LearningAgreementCreditsEquivalencePage";
import {CreditsCoefficientProvider} from "../utils/context/CreditsCoefficientProvider";

const routes = [
    {path: '/mobility_new', element: <MobilityCreatePage />},
    {path: '/mobility/:mobilityId', element: <MobilityPage />},
    {path: '/mobility/:mobilityId/edit', element: <MobilityEditPage />},
    {path: '/mobility/:mobilityId/learning_agreement', element: <LearningAgreementOverviewPage />},
    {path: '/mobility/:mobilityId/learning_agreement/credits_equivalence', element: <CreditsCoefficientProvider><LearningAgreementCreditsEquivalencePage /></CreditsCoefficientProvider>},
    {path: '/mobility/:mobilityId/learning_agreement_sending_courses', element: <SendingHeiCoursesSelectionPage />},
    {path: '/mobility/:mobilityId/learning_agreement_receiving_courses', element: <ReceivingHeiCoursesSelectionPage />},
]

export default routes