import React, {useEffect, useState} from "react"
import {Mobility} from "../mobility/Mobility";
import {StudentDetails} from "../student/StudentDetails";
import {DynamicDocument} from "../common/DynamicDocument";
import {CourseGradesTable} from "./CourseGradeTable";

export const CreditsRecognition = ({transcriptOfRecords}) => {
    const [courseGrades, setCourseGrades] = useState([])

    // Isolate the grades for courses that originated from the learning agreement and ignore the ones added in the transcript of records
    useEffect(() => {
        let recognitionCourseGrades = []

        if (transcriptOfRecords !== null) {
            transcriptOfRecords.courseGrades.forEach(
                (courseGrade) => {
                    if (courseGrade.course.originatingFromLearningAgreement) {
                        recognitionCourseGrades.push(courseGrade)
                    }
                }
            )
        }

        setCourseGrades(recognitionCourseGrades)
    }, [transcriptOfRecords])

    return (
        transcriptOfRecords !== null
        && transcriptOfRecords !== undefined
        && transcriptOfRecords.hasOwnProperty('mobility')
        && transcriptOfRecords.hasOwnProperty('student')
        &&
        <DynamicDocument sections={[
            {
                anchor: 'mobility',
                header: 'Mobility details',
                body: <Mobility mobility={transcriptOfRecords.mobility}/>
            },
            {
                anchor: 'student',
                header: 'Student details',
                body: <StudentDetails student={transcriptOfRecords.student}/>
            },
            {
                anchor: 'grades',
                header: 'Courses and grades at receiving university with credits equivalence for home/sending universities',
                body: <CourseGradesTable courseGrades={courseGrades}/>
            },
        ]}/>
    )
}