import React from 'react'
import {Table} from 'react-bootstrap'
import {getTotalCreditsFromCourses} from "../../utils/Utils";
import {RecognitionConditionsPopover} from "./RecognitionConditionsPopover";
import {ShortDescriptionPopover} from "./ShortDescriptionPopover";

export const CoursesTable = ({ courses }) => {

  if(Object.keys(courses).length !== 0) {
    return (
        <>
            <p className="mt-4"><span className="fw-bold">{getTotalCreditsFromCourses(courses)}</span> selected credits</p>
            <Table striped bordered hover>
                <thead>
                <tr>
                    <th>Code</th>
                    <th>Title</th>
                    <th>Credits</th>
                    <th>Details</th>
                </tr>
                </thead>
                <tbody>
                {
                    courses.map((course, i) => {
                        return (
                            <tr key={i}>
                                <td>{course.losCode}</td>
                                <td>{course.title}</td>
                                <td>{course.credits}</td>
                                <td>
                                    <ShortDescriptionPopover course={course}/>
                                    <RecognitionConditionsPopover course={course}/>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
            <p className="mt-4"><span className="fw-bold">{getTotalCreditsFromCourses(courses)}</span> selected credits</p>
        </>
    )
  }
}
