import React, { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import {Row, Col, Alert, ButtonGroup, Button} from 'react-bootstrap'
import {
    prettifyDate,
} from '../../utils/Utils'
import { FilteredTable } from '../mobility/FilteredTable'

import { OutgoingCounts } from "../learningAgreement/OutgoingCounts"
import { Status as LAStatus} from "../learningAgreement/Status"
import { Status as ToRStatus} from "../transcriptOfRecords/Status"
import {FilePdf} from "react-bootstrap-icons"

import {IncomingCounts} from "../learningAgreement/IncomingCounts";
import {Page} from "./Page";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";
import {LearningAgreement as LearningAgreementIcon} from "../icons/LearningAgreement";
import {TranscriptOfRecords as TranscriptOfRecordsIcon} from "../icons/TranscriptOfRecords";
import {CreditsRecognition as CreditsRecognitionIcon} from "../icons/CreditsRecognition";


// Create table headers
const tableHeaders = [
    { title: 'Student', prop: 'student', isSortable: true },
    { title: 'Mobility (Start)', prop: 'mobility_start', isSortable: true },
    { title: 'Mobility (End)', prop: 'mobility_end', isSortable: true },
    { title: 'University', prop: 'hei' },
    { title: 'Documents', prop: 'documents' },
]

export const MobilitiesPage = ({mobilityMode}) => {
    const { schacCode } = useParams()

    const [loading, setLoading] = useState(true)

    const [totalMobilities, setTotalMobilities] = useState(0)
    const [mobilitiesFormattedFilters, setMobilitiesFormattedFilters] = useState({})

    const [tableEntries, setTableEntries] = useState([])
    const [mobilitiesEndpointUrl, setMobilitiesEndpointUrl] = useState('')

    // Hei
    const {
        errorNotFound: errorHeiNotFound,
        isLoaded: isHeiLoaded,
        data: hei,
        queryApi: heiQueryApi
    } = useApi(
        {
            url: '/api/heis/'+schacCode+'/details',
            method: 'get'
        }
    )

    // Mobilities
    const {
        data: mobilities,
        queryApi: mobilitiesQueryApi
    } = useApi(
        {
            url: mobilitiesEndpointUrl,
            method: 'get',
            isCollection: true,
            queryParameters: mobilitiesFormattedFilters,
            thenCallback: (responseData) => {
                setTotalMobilities(responseData['hydra:totalItems'])
            },
            finallyCallback: () => {
                setLoading(false)
            }
        }
    )

    useEffect(() => {
        heiQueryApi()
    }, [schacCode])

    useEffect(() => {
        setMobilitiesEndpointUrl(
            isMobilityModeIncoming()?
                '/api/heis/'+schacCode+'/incoming_mobilities'
                : '/api/heis/'+schacCode+'/outgoing_mobilities'
        )
    }, [mobilityMode, schacCode])

    useEffect(() => {
        if (mobilitiesEndpointUrl) {
            refreshTableData()
        }
    }, [mobilitiesEndpointUrl])

    useEffect(() => {
        if (mobilities) {
            setBody()
        }
    },[mobilities])

    useEffect(() => {
        refreshTableData()
    },[mobilitiesFormattedFilters])

    const isMobilityModeIncoming = () => {
        return mobilityMode === "incoming"
    }

    const updateFilters = (filterData = {}) => {
        setLoading(true)

        let formattedFilterData = {}

        Object.keys(filterData).forEach((filterInputId) => {
            let filterValue = filterData[filterInputId]
            if (filterValue !== '') {
                formattedFilterData[filterInputId] = filterValue
            }
        })

        setMobilitiesFormattedFilters(formattedFilterData)
    }

    const refreshTableData = () => {
        mobilitiesQueryApi()
    }

    let documents

    // Create table body
    const setBody = () => {
        let arrayBody = []

        mobilities.forEach(
            mobility => {
                let item = {
                    student: mobility.student.contactDetails.familyNames + " " + mobility.student.contactDetails.givenNames,
                    mobility_start: prettifyDate(mobility.startDate),
                    mobility_end: prettifyDate(mobility.endDate),
                    hei: isMobilityModeIncoming() ? mobility.sendingHei.name : mobility.receivingHei.name,
                    documents:
                        <ul>
                            {
                                [
                                    {
                                        label: 'Learning agreement',
                                        source: mobility.learningAgreement,
                                        currentVersionLink: '/learning_agreement/' + mobility.id,
                                        icon: <LearningAgreementIcon/>,
                                        status: <LAStatus learningAgreement={mobility.learningAgreement} />,
                                        disabledTitle: 'PDF only generated when the learning agreement is accepted by both universities',
                                    },
                                    {
                                        label: 'Transcript of records',
                                        source: mobility.transcriptOfRecords,
                                        createButton: isMobilityModeIncoming() ?
                                            <Button variant={'primary'} as={Link} to={'/transcript_of_records/' + mobility.id + '/create'}>
                                                <TranscriptOfRecordsIcon/> Create transcript of records
                                            </Button>
                                            :
                                            undefined,
                                        currentVersionLink: '/transcript_of_records/' + mobility.id,
                                        icon: <TranscriptOfRecordsIcon/>,
                                        status: <ToRStatus transcriptOfRecords={mobility.transcriptOfRecords} />,
                                        disabledTitle: 'PDF only generated when the transcript of records is submitted by the receiving university',
                                    },
                                    {
                                        label: 'Credits recognition',
                                        source: mobility.transcriptOfRecords,
                                        icon: <CreditsRecognitionIcon/>,
                                        currentVersionLink: '/credits_recognition/' + mobility.id,
                                        disabledTitle: 'PDF only generated when the transcript of records is submitted by the receiving university',
                                    },
                                ].map(
                                    (document, index) =>
                                        <li key={index} className={'mb-2'}>
                                            <ButtonGroup size={'sm'}>
                                                {
                                                    document.source !== undefined
                                                    && document.source.pdf !== undefined ?
                                                        <a download target="_blank" className={'btn btn-outline-secondary'} href={process.env.REACT_APP_API_URL + mobility.learningAgreement.pdf} rel="noreferrer">
                                                            <FilePdf />
                                                        </a>
                                                        :
                                                        <Button
                                                            variant={'outline-secondary'}
                                                            title={document.disabledTitle}
                                                            disabled
                                                        >
                                                            <FilePdf/>
                                                        </Button>
                                                }

                                                {
                                                    document.source !== undefined ?
                                                            <Button
                                                                as={Link}
                                                                to={document.currentVersionLink}
                                                                variant={'outline-primary'}
                                                            >
                                                                { document.icon } { document.label } { document.status }
                                                            </Button>
                                                        :
                                                        document.createButton !== undefined ?
                                                            document.createButton
                                                            :
                                                            <Button
                                                                disabled
                                                                variant={'outline-primary'}
                                                            >
                                                                { document.icon } { document.label } { document.status }
                                                            </Button>
                                                }
                                            </ButtonGroup>
                                        </li>
                                )
                            }
                        </ul>,
                }

                arrayBody.push(item)
            })

        setTableEntries(arrayBody)
    }

    return (
        <Page
            breadcrumbs={[
                {label: 'University', url: '/university/' + schacCode},
                {
                    label: isMobilityModeIncoming() ? "Incoming mobilities" : "Outgoing mobilities",
                    active: true
                },
            ]}
            title={<>{isMobilityModeIncoming() ? "Incoming mobilities" : "Outgoing mobilities"} {" - "} {hei !== null && hei.name}</>}
        >
            {
                errorHeiNotFound ?
                    <Alert variant={'danger'}>
                        No university found for schac code "{schacCode}". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isHeiLoaded?
                        <LoadingSpinner/>
                        :
                        hei !== null &&
                        <>
                            <Row>
                                <Col>
                                    {
                                        isMobilityModeIncoming()
                                            ? (<Link to={'/university/'+hei.schacCode+'/outgoing_mobilities'}>Go to "Outgoing mobilities" ></Link>)
                                            : (<Link to={'/university/'+hei.schacCode+'/incoming_mobilities'}>Go to "Incoming mobilities" ></Link>)
                                    }
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <h5 className="mb-3">Learning Agreements ({ totalMobilities })</h5>
                                    {
                                        (isMobilityModeIncoming()) ?
                                            (
                                                <IncomingCounts
                                                    hei={hei}
                                                />
                                            )
                                            :(
                                                <OutgoingCounts
                                                    hei={hei}
                                                />
                                            )
                                    }
                                </Col>
                            </Row>

                            <Row className="mt-5">
                                <Col>
                                    <FilteredTable
                                        tableEntries={tableEntries}
                                        tableHeaders={tableHeaders}
                                        filterCallback={updateFilters}
                                        loading={loading}
                                    />
                                </Col>
                            </Row>
                        </>
            }
        </Page>
    )

}