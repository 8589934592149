import React from 'react'
import {
  DatatableWrapper,
  TableBody,
  TableHeader,
  Pagination
} from 'react-bs-datatable'
import { Row, Col, Table, Spinner } from 'react-bootstrap'
import {MobilityFilterForm} from "../form/filters/MobilityFilterForm";

export const FilteredTable = ({tableEntries, tableHeaders, filterCallback, loading}) => {

  return (
      <>

      <MobilityFilterForm filterCallback={filterCallback} />

        {
          loading ?
          (
            <Spinner animation="border" role="status">
              <span className="visually-hidden">Loading...</span>
            </Spinner>
          ) :
          (
            <DatatableWrapper body={tableEntries} headers={tableHeaders}>
              <Row className="mb-4">
                <Col
                    xs={12}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                >
                  {/*<Filter />*/}
                </Col>
                <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-lg-center align-items-center justify-content-sm-start mb-2 mb-sm-0"
                >
                  {/*<PaginationOptions />*/}
                </Col>
                <Col
                    xs={12}
                    sm={6}
                    lg={4}
                    className="d-flex flex-col justify-content-end align-items-end"
                >
                  <Pagination />
                </Col>
              </Row>
              <Table striped bordered hover>
                <TableHeader />
                <TableBody />
              </Table>
            </DatatableWrapper>
          )
        }
      </>
  )
}