import React from "react";
import {LongTextFieldPopover} from "./LongTextFieldPopover";

export const ShortDescriptionPopover = ({course}) => {
    return (
        <LongTextFieldPopover
            header={'Course description'}
            course={course}
            fieldKey={'shortDescription'}
            labelIfUndefined={'No description added by the student'}
        />
    )
}