import React, {useEffect, useState} from 'react'
import {Alert, Badge, Button, Collapse, ListGroup, Table} from 'react-bootstrap'
import {CheckLg, ExclamationTriangle, QuestionCircle, X} from "react-bootstrap-icons";
import {ShortDescriptionPopover} from "./ShortDescriptionPopover";
import {RecognitionConditionsPopover} from "./RecognitionConditionsPopover";

export const CourseGradesTable = ({ courseGrades, creditsKey, legend }) => {
    const [creditsCounts, setCreditsCounts] = useState({})
    const [showLegend, setShowLegend] = useState(false)


    useEffect(() => {
        let totalCreditsFromLearningAgreement = 0
        let totalCreditsNotFromLearningAgreement = 0
        let validatedCreditsFromLearningAgreement = 0
        let validatedCreditsNotFromLearningAgreement = 0

        courseGrades.forEach(
            (courseGrade) => {
                let course = courseGrade.course
                let credits = course[creditsKey]

                if (course.originatingFromLearningAgreement) {
                    totalCreditsFromLearningAgreement += credits

                    if (courseGrade.passed) {
                        validatedCreditsFromLearningAgreement += credits
                    }
                } else {
                    totalCreditsNotFromLearningAgreement += credits

                    if (courseGrade.passed) {
                        validatedCreditsNotFromLearningAgreement += credits
                    }
                }
            }
        )

        let counts = {
            totalCreditsFromLearningAgreement,
            totalCreditsNotFromLearningAgreement,
            validatedCreditsFromLearningAgreement,
            validatedCreditsNotFromLearningAgreement
        }

        setCreditsCounts(counts)
    }, [courseGrades])

  if(Object.keys(courseGrades).length !== 0) {
    return (
        <>
            {
                creditsCounts.validatedCreditsFromLearningAgreement === creditsCounts.totalCreditsFromLearningAgreement?
                    <Alert variant={'success'}>
                        <CheckLg/> <strong>{creditsCounts.validatedCreditsFromLearningAgreement}/{creditsCounts.totalCreditsFromLearningAgreement} credits</strong> validated for courses <strong>from the learning agreement</strong>.
                        <br/>All of the course credits listed below can automatically be recognized at the student's home/sending university.
                    </Alert>
                    : <Alert variant={'danger'}>
                        <ExclamationTriangle/> <strong>{creditsCounts.validatedCreditsFromLearningAgreement}/{creditsCounts.totalCreditsFromLearningAgreement} credits</strong> validated for courses <strong>from the learning agreement</strong>.
                        <br/>The student must contact their home/sending university in order to take the next steps.
                    </Alert>
            }
            {
                creditsCounts.totalCreditsNotFromLearningAgreement === 0 ?
                    <Alert variant={'info'}>
                        <CheckLg/> All courses in the transcript of records originated from the learning agreement
                    </Alert>
                    :creditsCounts.validatedCreditsNotFromLearningAgreement === creditsCounts.totalCreditsNotFromLearningAgreement?
                        <Alert variant={'info'}>
                            <CheckLg/> <strong>{creditsCounts.validatedCreditsNotFromLearningAgreement}/{creditsCounts.totalCreditsNotFromLearningAgreement} credits</strong> validated for courses <strong>NOT originating from the learning agreement</strong>
                        </Alert>
                        : <Alert variant={'warning'}>
                            <ExclamationTriangle/> <strong>{creditsCounts.validatedCreditsNotFromLearningAgreement}/{creditsCounts.totalCreditsNotFromLearningAgreement} credits</strong> validated for courses <strong>NOT originated from the learning agreement</strong>
                        </Alert>
            }


            <Button
                variant={'link'}
                onClick={() => setShowLegend(!showLegend)}
                aria-controls="course-table"
                aria-expanded={showLegend}
            >
                <QuestionCircle/> Display color legend for table entries
            </Button>
            <Collapse in={showLegend}>
                <ListGroup horizontal id={'course-table'} className={'mb-3'}>
                    {
                        legend.map(
                            (legendItem, index) =>
                                <ListGroup.Item variant={legendItem.variant} key={index}>
                                    {legendItem.label}
                                </ListGroup.Item>
                        )
                    }
                </ListGroup>
            </Collapse>
            <Table bordered hover>
                <thead>
                <tr>
                    <th>#</th>
                    <th>Title</th>
                    <th>Credits</th>
                    <th>Grade</th>
                    <th>Details</th>
                </tr>
                </thead>
                <tbody>
                {
                    courseGrades.map((courseGrade, index) => {
                        return (
                            <tr key={index} className={
                                courseGrade.passed ?
                                    courseGrade.course.originatingFromLearningAgreement?
                                        'table-success text-success'
                                        : 'text-success'
                                    :courseGrade.course.originatingFromLearningAgreement?
                                        'table-danger text-danger'
                                        : 'text-warning'
                            }>
                                <td>{courseGrade.course.losCode}</td>
                                <td>{courseGrade.course.title}</td>
                                <td>{courseGrade.course[creditsKey]}</td>
                                <td>
                                    {
                                        courseGrade.passed ?
                                            (<Badge bg={'success'}><CheckLg/> Pass</Badge>)
                                            : (<Badge bg={'danger'}><X/> Fail</Badge>)
                                    } { courseGrade.grade !== undefined ? courseGrade.grade: 'N/A' }
                                </td>
                                <td>
                                    <ShortDescriptionPopover course={courseGrade.course}/>
                                    <RecognitionConditionsPopover course={courseGrade.course}/>
                                </td>
                            </tr>
                        )
                    })
                }
                </tbody>
            </Table>
        </>
    )
  }
}
