import React, {useEffect} from 'react'
import {Link, useParams} from "react-router-dom";
import {HeiNameWithSchacCode} from "../hei/HeiNameWithSchacCode";
import {Overview} from "../hei/Overview";
import {Alert} from "react-bootstrap";
import {Page} from "./Page";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";

export const HeiPage = () => {
    let { schacCode } = useParams()

    const {
        errorNotFound: errorHeiNotFound,
        isLoaded: isHeiLoaded,
        data: hei,
        queryApi: heiQueryApi
    } = useApi(
        {
            url: '/api/heis/'+schacCode+'/details',
            method: 'get',
        }
    )

    useEffect(
        () => {
            heiQueryApi()
        }, [schacCode]
    )

    return (
        <Page
            breadcrumbs={[
                {label: 'University', active: true},
            ]}
            title={<HeiNameWithSchacCode hei={hei} />}
        >
            {
                errorHeiNotFound ?
                    <Alert variant={'danger'}>
                        No university found for schac code "{schacCode}". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isHeiLoaded?
                        <LoadingSpinner/>
                        :
                        <Overview hei={hei} />
            }
        </Page>
    )
}