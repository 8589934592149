import React from 'react'
import {createGenderLabelValuePair} from "../../../utils/Utils";
import {Select} from "../base/Select";

export const SelectGender = ({controlId, label = 'Gender', placeholder = 'Select gender',
                                 className = 'mb-3'}) => {
    const options = createGenderLabelValuePair()

    return <Select
        controlId={controlId}
        label={label}
        placeholder={placeholder}
        className={className}
        options={options}
    />
}