import React, {useContext, useState} from 'react'
import {Alert, Button, ButtonGroup} from "react-bootstrap";
import Modal from 'react-bootstrap/Modal';
import {RejectionAlert} from "./RejectionAlert";
import {CheckCircle, InfoCircle, XCircle} from "react-bootstrap-icons";
import {Form} from "../form/base/Form";
import {UserContext} from "../../utils/context/UserProvider";
import {Link} from "react-router-dom";
import {FlashBagContext} from "../../utils/context/FlashBagProvider";
import {useApi} from "../../utils/hooks/useApi";

export const LearningAgreementStaff = ({learningAgreement, acceptCallback, rejectCallback}) => {
    const [showConfirmAcceptModal, setShowConfirmAcceptModal] = useState(false);
    const [showConfirmRejectModal, setShowConfirmRejectModal] = useState(false);
    const {isCurrentUserStaffAtHei} = useContext(UserContext)
    const {addFlash} = useContext(FlashBagContext)

    // Learning agreement receiving accept
    const {
        queryApi: learningAgreementReceivingAcceptQueryApi,
    } = useApi(
        {
            url: '/api/learning_agreements/'+learningAgreement?.id + '/receiving/accept',
            method: 'patch',
            thenCallback: () => {
                addFlash(
                    {
                        message: 'The learning agreement was accepted and is now complete. Notifications were sent to the student and their home/sending university.',
                        severity: 'success'
                    }
                )
                acceptCallback()
            }
        }
    )


    const isStaffFromSendingHei = () => {
        return isCurrentUserStaffAtHei(learningAgreement.mobility.sendingHei.schacCode)
    }
    const isStaffFromReceivingHei = () => {
        return isCurrentUserStaffAtHei(learningAgreement.mobility.receivingHei.schacCode)
    }

    const sendingHeiMode = (learningAgreement.status === 'submitted_by_student' && isStaffFromSendingHei())
    const receivingHeiMode = (learningAgreement.status === 'accepted_by_sending' && isStaffFromReceivingHei())

    const handleAcceptation = () => {
        if (isStaffFromReceivingHei()) {
            learningAgreementReceivingAcceptQueryApi()
        }

        handleCloseConfirmAcceptModal()
    }

    const handleCloseConfirmAcceptModal = () => {
        setShowConfirmAcceptModal(false);
    }
    const handleShowConfirmAcceptModal = () => {
        setShowConfirmAcceptModal(true);
    }

    const handleCloseConfirmRejectModal = () => {
        setShowConfirmRejectModal(false);
    }
    const handleShowConfirmRejectModal = () => {
        setShowConfirmRejectModal(true);
    }

    return (
        <>
            <RejectionAlert
                learningAgreement={learningAgreement}
                mode={ isStaffFromSendingHei ? 'sendingHei': 'receivingHei'}
            />

            {
                (
                    sendingHeiMode
                    || receivingHeiMode
                ) &&
                    (
                        <>

                            <Alert variant={"info"}>
                                <p>
                                    <InfoCircle/> As the <strong>{sendingHeiMode ?'home/sending':'host/receiving'} university</strong>, your role is to analyse the submitted learning agreement and to make the decision to <strong>accept</strong> or <strong>reject</strong> the student's submission.
                                </p>
                                <p>
                                    If you choose to reject the learning agreement, you will need to provide clear comments and directions for the student to apply modifications and submit the learning agreement once more after they receive a notification of your decision.
                                    <br/>If you choose to accept the learning agreement, {
                                    sendingHeiMode ?
                                        'you will be taken to an additional page in which you will be assisted to enter the credits equivalence in your university for the chosen mobility\'s courses. The student will be notified of your decision and the receiving university\'s review process will start as they also receive a notification.'
                                        : 'all parties involved will receive a notification and the learning agreement will be considered complete.'
                                }
                                </p>

                                <ButtonGroup className="mt-2">
                                    {
                                        sendingHeiMode?
                                            <Button as={Link} to={'/mobility/'+learningAgreement.mobility.id+'/learning_agreement/credits_equivalence'} variant={'success'}><CheckCircle /> Enter credits equivalence before accepting</Button>
                                            : <Button variant={'success'} onClick={handleShowConfirmAcceptModal}><CheckCircle /> Accept</Button>

                                    }
                                    <Button variant={'danger'} onClick={handleShowConfirmRejectModal}><XCircle /> Reject</Button>
                                </ButtonGroup>
                            </Alert>

                            <Modal show={showConfirmAcceptModal} onHide={handleCloseConfirmAcceptModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Accept the learning agreement</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <Alert variant={"warning"}>
                                        <p>
                                            You are about to accept the learning agreement.
                                            This action is irreversible and will trigger a notification to
                                            {
                                                (sendingHeiMode)?
                                                    ('the receiving university that will then need to accept or reject it')
                                                    :('the student and their home university')
                                            }.
                                        </p>

                                        <br /><br /><strong>Would you like to proceed?</strong>
                                    </Alert>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={handleCloseConfirmAcceptModal}>
                                        Cancel
                                    </Button>
                                    <Button variant="primary" onClick={handleAcceptation}>
                                        Confirm
                                    </Button>
                                </Modal.Footer>
                            </Modal>

                            <Modal show={showConfirmRejectModal} onHide={handleCloseConfirmRejectModal}>
                                <Modal.Header closeButton>
                                    <Modal.Title>Reject the learning agreement</Modal.Title>
                                </Modal.Header>
                                <Form
                                    before={
                                        <Alert variant={'warning'}>
                                            You are about to reject this learning agreement. Please provide comments explaining your decision and guiding the student through the required changes:
                                        </Alert>
                                    }
                                    fields={[{
                                        type: 'textarea',
                                        controlId: sendingHeiMode? 'rejectionSendingComment': 'rejectionReceivingComment',
                                        colWrapperMd: 12,
                                        label: 'Rejection comments',
                                    }]}
                                    after={
                                        <Alert variant={'warning'}>
                                            This action is irreversible and will trigger a notification for the student{
                                            (receivingHeiMode)??
                                            (', and their home university,')
                                        } that will need to revise their learning agreement accordingly before submitting it again.

                                            <br /><br /><strong>Would you like to proceed?</strong>
                                        </Alert>
                                    }
                                    isModal={true}
                                    submitButtonLabel={'Confirm rejection'}
                                    submitButtonVariant={'danger'}
                                    showBottomRequiredHelp={false}
                                    data={learningAgreement}
                                    method={'patch'}
                                    submitUrl={
                                        sendingHeiMode?
                                            '/api/learning_agreements/' + learningAgreement.id + '/sending/reject'
                                            :'/api/learning_agreements/' + learningAgreement.id + '/receiving/reject'
                                    }
                                    redirectOnSuccess={
                                        () => {
                                            rejectCallback()
                                            handleCloseConfirmRejectModal()
                                            return '/learning_agreement/' + learningAgreement.mobility.id
                                        }
                                    }
                                    successMessage={'You rejected the learning agreement. The student' + (!sendingHeiMode? ' and their sending university': '') + ' received a notification.'}
                                />
                            </Modal>
                        </>
                    )
            }
        </>
    )
}
