import {eqfLevels} from "../../utils/GlobalVars";
import {Alert} from "react-bootstrap";

export const CreditsForGraduation = ({hei}) => {
    return (
        hei.creditsForGraduation === undefined ?
            <Alert variant="warning">This university has not yet communicated their settings.</Alert>
            :<table className={'table table-striped'}>
            <thead>
            <tr>
                <th>Degree (EQF level)</th>
                <th>Credits required to graduate</th>
            </tr>
            </thead>
            <tbody>
            {
                [5, 6, 7, 8].map(
                    (eqfLevel, index) =>
                        <tr key={index + '-eqf-' + eqfLevel}>
                            <th>{eqfLevels[eqfLevel]}</th>
                            <td>
                                {hei.creditsForGraduation[eqfLevel] }
                            </td>
                        </tr>
                )
            }
            </tbody>
        </table>
    )
}