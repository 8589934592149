import React from 'react'
import {FormField} from "../base/Form";
import {eqfLevels} from "../../../utils/GlobalVars";

export const CreditsForGraduation = ({controlId, eqfLevelsToDisplay = [5, 6, 7, 8]}) => {

    return <table className={'table table-striped'}>
        <thead>
            <tr>
                <th>Degree (EQF level)</th>
                <th>Credits required to graduate</th>
            </tr>
        </thead>
        <tbody>
            {
                eqfLevelsToDisplay.map(
                    (eqfLevel, index) =>
                        <tr key={index + '-eqf-' + eqfLevel}>
                            <th>{eqfLevels[eqfLevel]}</th>
                            <td>
                                <FormField
                                    field={{
                                        label: 'Credits required to graduate',
                                        colWrapperMd: 12,
                                        controlId: controlId + '.' + eqfLevel,
                                        type: 'number',
                                        step: 0.01,
                                    }}
                                />
                            </td>
                        </tr>
                )
            }
        </tbody>
    </table>
}