import React, {useContext} from 'react'
import {Button, ButtonGroup, Card, Col, OverlayTrigger, Popover, Row} from "react-bootstrap";
import {Link, useNavigate} from "react-router-dom";
import {TimelineIndicator} from "./TimelineIndicator";
import {prettifyDate} from "../../utils/Utils";
import {Eye, FilePdf, ListTask, Pencil, PlusLg, Trash} from "react-bootstrap-icons";
import {LearningAgreement as LearningAgreementIcon} from "../icons/LearningAgreement";
import {Status} from "../learningAgreement/Status";
import {RejectionAlert} from "../learningAgreement/RejectionAlert";
import {TranscriptOfRecords as TranscriptOfRecordsIcon} from "../icons/TranscriptOfRecords";
import {CreditsRecognition as CreditsRecognitionIcon} from "../icons/CreditsRecognition";
import {Button as CreditsRecognitionButton} from "../creditsRecognition/Button";
import {useApi} from "../../utils/hooks/useApi";
import {UserContext} from "../../utils/context/UserProvider";

export const MobilityCard = ({mobility}) => {
  const {refreshCurrentUserStudentMobilities} = useContext(UserContext)
  const navigate = useNavigate()

  const {
    queryApi: mobilityDeleteQueryApi
  } = useApi(
      {
        url: '/api/mobilities/' + mobility?.id,
        method: 'delete',
        thenCallback: () => {
          refreshCurrentUserStudentMobilities()
          navigate('/')
        }
      }
  )

  const deleteMobility = () => {
    mobilityDeleteQueryApi()
  }

  return (
      mobility !== null &&
      <Card className="d-flex flex-column justify-content-between mb-3">
        <Card.Header>
          <Card.Title>
            <Row>
              <Col md={9}>
                <Link
                    to={'/mobility/'+mobility.id}
                >
                  <h6>
                    @<strong>{mobility.receivingHei.name}</strong>
                    <br /><small className="text-muted"><TimelineIndicator mobility={mobility}/> From {prettifyDate(mobility.startDate)} to {prettifyDate(mobility.endDate)}</small>
                  </h6>
                </Link>
              </Col>
              <Col md={3}>
                <ButtonGroup>
                  {
                    // if mobility can be edited
                    mobility.learningAgreement === undefined
                    || mobility.learningAgreement.status === 'draft'
                    || mobility.learningAgreement.status === 'rejected_by_sending'
                    || mobility.learningAgreement.status === 'rejected_by_receiving'
                     ?
                        <Button
                            variant="outline-secondary"
                            as={Link}
                            title={'Edit mobility'}
                            to={'/mobility/'+mobility.id+'/edit'}
                        >
                          <Pencil/>
                        </Button>
                        :
                        <Button
                            variant="outline-secondary"
                            disabled
                            title={'Edit mobility'}
                        >
                          <Pencil/>
                        </Button>
                  }

                  {
                    // if mobility can be deleted
                    mobility.learningAgreement === undefined
                    || mobility.learningAgreement === null
                    || mobility.learningAgreement.status === 'draft' ?
                        <Button
                            variant="outline-danger"
                            onClick={deleteMobility}
                            title={'Delete mobility'}
                        >
                          <Trash/>
                        </Button>
                        :
                        <Button
                            variant="outline-danger"
                            disabled
                            title={'Delete mobility'}
                        >
                          <Trash/>
                        </Button>
                  }
                </ButtonGroup>
              </Col>
            </Row>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <Card.Title><LearningAgreementIcon/> Learning agreement {typeof mobility.learningAgreement !== 'undefined' && mobility.learningAgreement !== null && <Status learningAgreement={mobility.learningAgreement} />}</Card.Title>

          {
            (typeof mobility.learningAgreement !== 'undefined' && mobility.learningAgreement !== null)?
                <ButtonGroup>
                  {
                    mobility.learningAgreement.status === 'draft' ?
                        <Button variant={'outline-primary'} as={Link}
                                to={'/mobility/'+mobility.id+'/learning_agreement'}><ListTask/> Complete and submit
                        </Button>
                        :
                        (mobility.learningAgreement.status === 'rejected_by_sending'
                            || mobility.learningAgreement.status === 'rejected_by_receiving') ?
                            <Button variant={'outline-primary'} as={Link}
                                    to={'/mobility/' + mobility.id + '/learning_agreement'}>
                              <Pencil/> Fix and re-submit
                            </Button>
                            :
                            <>
                              <Button variant={'outline-secondary'} as={Link}
                                      to={'/learning_agreement/' + mobility.id}><Eye/> View
                              </Button>
                              {
                                mobility.learningAgreement.status === 'accepted_by_receiving' ?
                                  <Button variant={'outline-secondary'} as={Link}
                                          to={'/mobility/' + mobility.id + '/learning_agreement'}>
                                    <Pencil/> Edit
                                  </Button>
                                  :
                                  <Button
                                      variant={'outline-secondary'}
                                      disabled
                                  >
                                    <Pencil/> Edit
                                  </Button>
                              }
                            </>
                  }

                  {
                      (mobility.learningAgreement.status === 'rejected_by_sending'
                          || mobility.learningAgreement.status === 'rejected_by_receiving') &&
                      <OverlayTrigger trigger={['hover', 'focus']} placement="bottom" overlay={
                        <Popover id="popover-basic">
                          <Popover.Header as="h3">Rejection comments</Popover.Header>
                          <Popover.Body>

                            <RejectionAlert
                                learningAgreement={mobility.learningAgreement}
                            />
                          </Popover.Body>
                        </Popover>
                      }>
                        <Button variant="outline-danger">Rejection comments</Button>
                      </OverlayTrigger>
                  }
                  {
                      (
                          mobility.learningAgreement.pdf !== undefined
                          && mobility.learningAgreement.pdf !== null
                      ) &&
                      <a download target="_blank" className={'btn btn-outline-secondary'} href={process.env.REACT_APP_API_URL + mobility.learningAgreement.pdf} rel="noreferrer">
                        <FilePdf /> Last accepted version
                      </a>
                  }
                </ButtonGroup>
                :
                (
                    <Button variant={'outline-primary'} as={Link} to={'/mobility/' + mobility.id + '/learning_agreement'}><PlusLg /> Create learning agreement</Button>
                )
          }
        </Card.Body>
        <Card.Body>
          <Card.Title><TranscriptOfRecordsIcon/> Transcript of records</Card.Title>
          <Card.Text>
            {
              mobility.transcriptOfRecords === undefined
              || mobility.transcriptOfRecords === null ?
                  <span className={'text-muted'}>
                                                             Transcript of records unavailable
                                                        </span>
                  : <Button
                      as={Link}
                      variant="outline-secondary"
                      to={'/transcript_of_records/' + mobility.id}>
                    <Eye/> View details
                  </Button>
            }
          </Card.Text>
        </Card.Body>
        <Card.Body>
          <Card.Title><CreditsRecognitionIcon/> Credits recognition</Card.Title>
          <Card.Text>
            <CreditsRecognitionButton mobility={mobility}/>
          </Card.Text>
        </Card.Body>
      </Card>
  )
}