import React, {Fragment, useContext} from 'react'
import {Overview} from "../hei/Overview";
import {UserContext} from "../../utils/context/UserProvider";
import {Page} from "../pages/Page";
import {HeiNameWithSchacCode} from "../hei/HeiNameWithSchacCode";

export const StaffDashboard = () => {
    const {currentUserStaffHeis} = useContext(UserContext)

    return (
        <Page
            breadcrumbs={[
                {label: 'Overview', active: true},
            ]}
            title={''}
        >
            {
                currentUserStaffHeis.map(
                    (hei, index) =>
                        <Fragment key={index + hei.schacCode}>
                            <h2><HeiNameWithSchacCode hei={hei} /></h2>
                            <Overview  hei={hei} />
                        </Fragment>
                )
            }
        </Page>
    )
}