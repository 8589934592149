import React from "react";
import {Button, OverlayTrigger, Popover} from "react-bootstrap";

export const LongTextFieldPopover = ({header, course, fieldKey, labelIfUndefined}) => {
    return (
        <OverlayTrigger trigger={['hover', 'focus']} rootClose placement="bottom" overlay={
            <Popover>
                <Popover.Body>
                    {
                        course[fieldKey] !== undefined ?
                            course[fieldKey]
                            : labelIfUndefined
                    }
                </Popover.Body>
            </Popover>
        }>
            <Button variant="outline-secondary">
                {header}
            </Button>
        </OverlayTrigger>
    )
}