import React, {useContext} from 'react'
import {SidebarContent} from "../student/SidebarContent"
import {SidebarContent as SidebarContentStaff} from "../staff/SidebarContent"
import {SidebarContent as SidebarContentStudent} from "../student/SidebarContent"

import {useAuth} from "../../utils/hooks/useAuth";
import {UserContext} from "../../utils/context/UserProvider";
import {Col} from "react-bootstrap";

export const Sidebar = () => {
    const {isAuthenticated} = useAuth()
    const {currentUserStaff, currentUserStudent} = useContext(UserContext)

    return (
        isAuthenticated() &&
        (
            <Col md={2} xl={2} className="bg-light">
                {
                    currentUserStaff !== null ?
                        <SidebarContentStaff />
                        :
                        currentUserStudent!== null &&
                        <SidebarContent />
                }
            </Col>
        )
    )
}

