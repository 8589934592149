import {CourseFieldArray} from "./CourseFieldArray";

export const ReceivingHeiCoursesSelection = [
    {
        colWrapperMd: 12,
        label: 'Learning outcomes at the receiving university',
        controlId: 'receivingHeiLearningOutcomesUrl',
        type: 'url',
    },
    {
        colWrapperMd: 12,
        label: 'Courses to attend at the receiving university',
        controlId: 'receivingCourses',
        type: CourseFieldArray,
    },
]