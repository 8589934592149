import React, {useEffect, useState} from 'react'
import {FieldArray} from "../base/FieldArray";
import {CourseCreditsEquivalence} from "./CourseCreditsEquivalence";
import {Alert, Badge} from "react-bootstrap";
import {useFormContext, useWatch} from "react-hook-form";

export const CourseCreditsEquivalenceFieldArray = (props) => {
    let prototype = {recognitionCredits: 0}

    const { getValues } = useFormContext();
    const [totalCreditsEquivalence, setTotalCreditsEquivalence] = useState(0)

    const receivingCourses = useWatch({
        name: 'receivingCourses'
    })

    let label = props.label || 'Courses credits equivalence'

    // Refresh total of credits entered by the user on input change
    useEffect(() => {
        let receivingCoursesData = getValues('receivingCourses')
        let total = 0
        receivingCoursesData.forEach(
            (receivingCourse) => {
                total += receivingCourse.recognitionCredits
            }
        )

        setTotalCreditsEquivalence(total)
    }, [getValues, receivingCourses])

    return <div>
        <FieldArray
            {...props}
            type={[
                {
                    colWrapperMd: 12,
                    controlId: 'recognitionCredits',
                    type: CourseCreditsEquivalence,
                },
            ]}
            allowAdd={false}
            allowDelete={false}
            prototype={prototype}
            label={ label }
        />

        <Alert variant={'warning'}>You have selected a total of <Badge bg={'secondary'}>{totalCreditsEquivalence}</Badge> credits validated in your university for the student's courses selection</Alert>
    </div>
}