import React, {useContext} from 'react'
import {BoxArrowInLeft, BoxArrowRight, Gear} from "react-bootstrap-icons";

import {UserContext} from "../../utils/context/UserProvider";
import {SidebarNav} from "../common/SidebarNav";
import {Nav} from "react-bootstrap";
import {Link} from "react-router-dom";



export const SidebarContent = () => {
  const {currentUserStaffHeis} = useContext(UserContext)

  return (
      <>
          <h6>Your information</h6>
          <Nav.Link as={Link} to={'/staff/settings'} className="px-0 ps-2 py-2 text-black">
              <small className="d-sm-inline text-black"><Gear /> Profile</small>
          </Nav.Link>

          <div
              className="d-flex flex-column align-items-center align-items-sm-start px-1 pt-2 text-black min-vh-100 mt-3">
              {currentUserStaffHeis.map((hei, index) =>
                  <div key={index + hei.schacCode}>
                      <SidebarNav
                          indexDiscriminant={hei.schacCode}
                          menuItems={
                              [
                                  {
                                      link: '/university/' + hei.schacCode,
                                      content: <h5 className="px-0 text-black lead">{hei.name}</h5>
                                  },
                                  {
                                      link: '/university/' + hei.schacCode + '/settings',
                                      content: <><Gear/> Settings</>
                                  },
                                  {
                                      link: '/university/' + hei.schacCode + '/incoming_mobilities',
                                      content: <><BoxArrowInLeft/> Incoming mobilities</>
                                  },
                                  {
                                      link: '/university/' + hei.schacCode + '/outgoing_mobilities',
                                      content: <><BoxArrowRight/> Outgoing mobilities</>
                                  },
                              ]
                          }
                      />
                  </div>
              )}
          </div>
      </>
  )
}

