import {SelectGender} from "./SelectGender";
import {SelectEqfLevel} from "./SelectEqfLevel";
import {SelectCitizenship} from "./SelectCitizenship";
import {ContactDetails} from "./ContactDetails";
import {LanguageLevelsFieldArray} from "./LanguageLevelsFieldArray";
import {SelectIscedFCode} from "./SelectIscedFCode";

export const StudentSettings = [
    {
        label: 'Birth date',
        controlId: 'birthDate',
        type: 'date',
    },
    {
        label: 'Gender',
        controlId: 'gender',
        type: SelectGender,
    },
    {
        controlId: 'eqfLevel',
        type: SelectEqfLevel,
    },
    {
        controlId: 'citizenship',
        type: SelectCitizenship,
    },
    {
        label: 'ISCED F code',
        controlId: 'iscedFCode',
        type: SelectIscedFCode,
    },
    {
        label: 'Contact preferences',
        controlId: 'contactDetails',
        colWrapperMd: 12,
        type: ContactDetails,
    },
    {
        colWrapperMd: 12,
        controlId: 'languageLevels',
        type: LanguageLevelsFieldArray,
    },
]