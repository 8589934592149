import React, {useContext, useEffect, useState} from 'react'
import {Link, useParams} from 'react-router-dom'
import {Alert, Badge, Button, Col, Collapse, Row} from 'react-bootstrap'
import {ExclamationTriangle, InfoCircle} from "react-bootstrap-icons";
import {CreditsEquivalenceForm} from "../form/CreditsEquivalenceForm";
import {Page} from "./Page";
import {CreditsForGraduation} from "../hei/CreditsForGraduation";
import {eqfLevels} from "../../utils/GlobalVars";
import {CreditsCoefficientContext} from "../../utils/context/CreditsCoefficientProvider";
import {LoadingSpinner} from "../common/LoadingSpinner";
import {useApi} from "../../utils/hooks/useApi";
import {FormulasExplanation} from "../creditsRecognition/FormulasExplanation";

export const LearningAgreementCreditsEquivalencePage = () => {
    const [showFullCreditsEquivalenceSending, setShowFullCreditsEquivalenceSending] = useState(false)
    const [showFullCreditsEquivalenceReceiving, setShowFullCreditsEquivalenceReceiving] = useState(false)

    const [totalCreditsRequiredAtSending, setTotalCreditsRequiredAtSending] = useState(null)
    const [totalCreditsRequiredAtReceiving, setTotalCreditsRequiredAtReceiving] = useState(null)

    const {creditsCoefficient, setCreditsCoefficient} = useContext(CreditsCoefficientContext)

    let { mobilityId } = useParams()

    // Learning agreement
    const {
        errorNotFound: errorLearningAgreementNotFound,
        isLoaded: isLearningAgreementLoaded,
        data: learningAgreement,
        queryApi: learningAgreementQueryApi
    } = useApi(
        {
            url: '/api/mobilities/'+mobilityId+'/learning_agreement',
            method: 'get',
        }
    )

    // Sending hei
    const {
        data: sendingHei,
        queryApi: sendingHeiQueryApi,
        resetData: resetSendingHei
    } = useApi(
        {
            url: learningAgreement?.mobility?.sendingHei['@id'],
            method: 'get',
        }
    )

    // Receiving hei
    const {
        data: receivingHei,
        queryApi: receivingHeiQueryApi,
        resetData: resetReceivingHei
    } = useApi(
        {
            url: learningAgreement?.mobility?.receivingHei['@id'],
            method: 'get',
        }
    )

    useEffect(() => {
        learningAgreementQueryApi()
    }, [mobilityId])

    useEffect(() => {
        resetSendingHei()
        resetReceivingHei()
        if (learningAgreement !== null) {
            sendingHeiQueryApi()
            receivingHeiQueryApi()
        }
    }, [learningAgreement])

    useEffect(() => {
        if (learningAgreement !== null) {
            let learningAgreementEqfLevel = learningAgreement.mobility.eqfLevelAtDeparture

            if (
                sendingHei !== null
                && sendingHei.hasOwnProperty('creditsForGraduation')
                && sendingHei.creditsForGraduation.hasOwnProperty(learningAgreementEqfLevel)
                && sendingHei.creditsForGraduation[learningAgreementEqfLevel] !== null
                && sendingHei.creditsForGraduation[learningAgreementEqfLevel] > 0
            ) {
                setTotalCreditsRequiredAtSending(
                    sendingHei.creditsForGraduation[learningAgreementEqfLevel]
                )
            } else {
                setTotalCreditsRequiredAtSending(null)
            }
        }
    }, [sendingHei])

    useEffect(() => {
        if (learningAgreement !== null) {
            let learningAgreementEqfLevel = learningAgreement.mobility.eqfLevelAtDeparture

            if (
                receivingHei !== null
                && receivingHei.hasOwnProperty('creditsForGraduation')
                && receivingHei.creditsForGraduation.hasOwnProperty(learningAgreementEqfLevel)
                && receivingHei.creditsForGraduation[learningAgreementEqfLevel] !== null
                && receivingHei.creditsForGraduation[learningAgreementEqfLevel] > 0
            ) {
                setTotalCreditsRequiredAtReceiving(
                    receivingHei.creditsForGraduation[learningAgreementEqfLevel]
                )
            } else {
                setTotalCreditsRequiredAtReceiving(null)
            }
        }
    }, [receivingHei])

    useEffect(() => {
        if (
            totalCreditsRequiredAtReceiving !== null
            && totalCreditsRequiredAtSending !== null
        ) {
            setCreditsCoefficient(totalCreditsRequiredAtSending/totalCreditsRequiredAtReceiving)
        } else {
            setCreditsCoefficient(1)
        }
    }, [totalCreditsRequiredAtSending, totalCreditsRequiredAtReceiving])

    return (
        <Page
            breadcrumbs={[
                {url: '/mobility/' + mobilityId, label: 'Mobility'},
                {url: '/mobility/' + mobilityId + '/learning_agreement', label: 'Learning agreement'},
                {label: 'Credits conversion', active: true},
            ]}
            title={'Credits conversion'}
        >
            <Alert variant={"info"}>
                <p>
                    <InfoCircle/> As the <strong>sending university</strong>, you must assign the equivalence of credits, for each selected course, from the receiving university's system to yours.
                    <br />This will enable automatic credits recognition at the end of the mobility, provided the student completes them all.
                </p>
                <p>
                    In order to simplify your task, the values have been pre-filled using the following calculation:
                    <br/><span className={'text-muted'}><ExclamationTriangle/> This estimation can only be applied if both universities filled the table for graduation credits in their settings.</span>
                </p>

                <div id="credits-equivalence-calculation">
                    <FormulasExplanation />
                </div>
            </Alert>

            {
                errorLearningAgreementNotFound ?
                    <Alert variant={'danger'}>
                        No learning agreement found with ID "{ mobilityId }". <Link to={'/'}>Go back to your mobilities overview</Link>.
                    </Alert>
                    :
                    !isLearningAgreementLoaded?
                        <LoadingSpinner/>
                        :
                        learningAgreement !== null
                        && sendingHei !== null
                        && receivingHei !== null
                        &&
                        <>
                            <h2>Enter credits equivalence for the chosen courses</h2>
                            <Alert variant={'info'}>
                                The student indicated that the mobility was part of the degree (EQF level): <strong>{eqfLevels[learningAgreement.mobility.eqfLevelAtDeparture]}</strong>.
                            </Alert>
                            <Row>
                                {
                                    [
                                        {
                                            title: <>Credits for graduation @{receivingHei.name}</>,
                                            hei: receivingHei,
                                            totalCreditsForGraduation: totalCreditsRequiredAtReceiving,
                                            showFullTable: showFullCreditsEquivalenceReceiving,
                                            setShowFullTable: setShowFullCreditsEquivalenceReceiving,
                                            collapsableId: 'receiving-credits-table'
                                        },
                                        {
                                            title: <>Your university's credits for graduation</>,
                                            hei: sendingHei,
                                            totalCreditsForGraduation: totalCreditsRequiredAtSending,
                                            showFullTable: showFullCreditsEquivalenceSending,
                                            setShowFullTable: setShowFullCreditsEquivalenceSending,
                                            collapsableId: 'sending-credits-table'
                                        },
                                    ].map(
                                        (heiData, index) =>
                                            <Col key={'hei-credits=' + heiData.schacCode + index}>
                                                <h3>{heiData.title}</h3>
                                                {
                                                    heiData.hei.creditsForGraduation === undefined ?
                                                        <Alert variant="warning"><ExclamationTriangle/> This university has not yet communicated their settings.</Alert>
                                                        :
                                                        <>
                                                            <p>
                                                                <Badge bg={'secondary'}>{heiData.totalCreditsForGraduation}</Badge> credits required to graduate a "<em>{eqfLevels[learningAgreement.mobility.eqfLevelAtDeparture]}</em>"
                                                                <br/><Button
                                                                variant={'link'}
                                                                onClick={() => heiData.setShowFullTable(!heiData.showFullTable)}
                                                                aria-controls={heiData.collapsableId}
                                                                aria-expanded={heiData.showFullTable}
                                                            >View complete table for graduation credits</Button>
                                                            </p>
                                                            <Collapse in={heiData.showFullTable}>
                                                                <div id={heiData.collapsableId}>
                                                                    <CreditsForGraduation hei={heiData.hei}/>
                                                                </div>
                                                            </Collapse>
                                                        </>
                                                }

                                            </Col>
                                    )
                                }
                            </Row>

                            <p>
                                <Badge bg={'success'}>{creditsCoefficient}</Badge> <Button variant={'link'} onClick={() => {window.scrollTo({ top: 0, behavior: 'smooth' })}}>Coefficient of equivalence</Button> <small className={'text-muted'}>used to pre-fill the following form</small>
                            </p>

                            {
                                (
                                    totalCreditsRequiredAtSending === null
                                    || totalCreditsRequiredAtReceiving
                                ) === null &&
                                <Alert variant={'warning'}>
                                    <ExclamationTriangle/> The credits equivalences were pre-filled with the same credits as in the receiving university because one, or more, of the table for graduation credits have not yet been communicated.
                                </Alert>
                            }

                            <CreditsEquivalenceForm learningAgreement={learningAgreement}/>
                        </>
            }
        </Page>
    )

}
